<script setup>
import { ref } from 'vue';

const props = defineProps({
    label: String,
    inputId: String,
    text: {
        type: String,
        default: 'Opslaan',
    },
    savingText: {
        type: String,
        default: 'Opslaan...',
    },
});

const button = ref(null);

const text = ref(props.text);

const saving = ref(false);

function setSaving(state) {
    saving.value = state;

    if (state) {
        button.value.setAttribute('disabled', 'disabled');
    } else {
        button.value.removeAttribute('disabled');
    }

    text.value = state ? props.savingText : props.text;
}

defineExpose({
    setSaving,
});
</script>

<template>
    <button ref="button" type="submit" 
    :class="['rounded-md px-3 py-2 text-sm font-semibold text-white shadow-xs focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 duration-200 cursor-pointer',
    saving ? 'bg-gray-400 hover:bg-gray-400 cursor-wait' : 'bg-fuchsia-500 hover:bg-fuchsia-500/80']">
        <span v-if="saving">
            <font-awesome-icon :icon="['fas', 'spinner']" class="animate-spin mr-1" />
        </span>
        {{ text }}
    </button>
</template>
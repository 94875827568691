<script setup>
import { ref, computed, onMounted } from 'vue';
import moment from 'moment/moment';
import 'moment/dist/locale/nl';

const props = defineProps({
    modelValue: {
        type: Object,
        default: () => ({
            preset: null,  // Changed from 'last7' to null to allow proper initialization
            startDate: null,
            endDate: null
        })
    },
    options: {
        type: Array,
        required: true
    },
    valueId: {
        type: String,
        default: 'id'
    },
    valueName: {
        type: String,
        default: 'name'
    },
    error: String
});

const emit = defineEmits(['update:modelValue']);

// Select state
const open = ref(false);
const selectButton = ref(null);
const selectView = ref(null);

// Datepicker state
const showDatepicker = ref(false);
const datepickerButton = ref(null);
const datepickerView = ref(null);
const currentMonth = ref(moment());

// Add selection state
const selecting = ref(false);
const tempStartDate = ref(null);
const tempEndDate = ref(null);

// Dropdown positioning
const style = computed(() => {
    if (!open.value || !selectView.value || !selectButton.value) return {};
    const rect = selectButton.value.getBoundingClientRect();
    const spaceBelow = window.innerHeight - rect.bottom;
    const viewHeight = selectView.value.offsetHeight;
    
    return spaceBelow < viewHeight ? { bottom: '100%' } : { top: '100%' };
});

// Date range calculations
const dateRange = computed(() => {
    const now = moment();
    switch (props.modelValue.preset) {
        case 'last7':
            return {
                start: now.clone().subtract(7, 'days'),
                end: now,
                label: 'Laatste 7 dagen'
            };
        case 'lastMonth':
            return {
                start: now.clone().startOf('month'),
                end: now.clone().endOf('month'),
                label: 'Afgelopen maand'
            };
        case 'last3Months':
            return {
                start: now.clone().subtract(2, 'months').startOf('month'),
                end: now,
                label: 'Laatste 3 maanden'
            };
        case 'last6Months':
            return {
                start: now.clone().subtract(5, 'months').startOf('month'),
                end: now,
                label: 'Laatste 6 maanden'
            };
        case 'thisYear':
            return {
                start: now.clone().startOf('year'),
                end: now.clone().endOf('year'),
                label: 'Huidig jaar'
            };
        case 'custom':
            if (props.modelValue.startDate && props.modelValue.endDate) {
                const start = moment(props.modelValue.startDate);
                const end = moment(props.modelValue.endDate);
                return {
                    start,
                    end,
                    label: `${start.format('D MMM')} - ${end.format('D MMM')}`
                };
            }
            return { start: null, end: null, label: 'Aangepaste periode' };
        default:
            return { start: null, end: null, label: 'Selecteer periode' };
    }
});

// Update daysToDisplay to include click handling states
const daysToDisplay = computed(() => {
    const firstDay = moment(currentMonth.value).startOf('month').startOf('isoWeek');
    const lastDay = moment(currentMonth.value).endOf('month').endOf('isoWeek');
    const days = [];
    let day = firstDay.clone();
    
    while (day.isSameOrBefore(lastDay)) {
        days.push({
            date: day.clone(),
            isInRange: day.isBetween(
                selecting.value ? tempStartDate.value : dateRange.value.start,
                selecting.value ? tempEndDate.value : dateRange.value.end,
                'day', '[]'
            ),
            isStart: day.isSame(selecting.value ? tempStartDate.value : dateRange.value.start, 'day'),
            isEnd: day.isSame(selecting.value ? tempEndDate.value : dateRange.value.end, 'day'),
            isCurrentMonth: day.month() === currentMonth.value.month(),
            isSelectable: true
        });
        day.add(1, 'day');
    }
    return days;
});

// Add function to format the display text
const getDisplayText = computed(() => {
    if (!props.modelValue.preset) return 'Selecteer datum';
    
    if (props.modelValue.preset === 'custom') {
        if (props.modelValue.startDate && props.modelValue.endDate) {
            const start = moment(props.modelValue.startDate);
            const end = moment(props.modelValue.endDate);
            return `${start.format('DD MMM YY')} - ${end.format('DD MMM YY')}`;
        }
        return 'Aangepaste periode';
    }
    
    return props.options.find(o => o[props.valueId] === props.modelValue.preset)?.[props.valueName];
});

// Event handlers
function setSelected(value) {
    emit('update:modelValue', {
        preset: value,
        startDate: dateRange.value.start?.format('YYYY-MM-DD'),
        endDate: dateRange.value.end?.format('YYYY-MM-DD')
    });
    open.value = false;
}

function prevMonth() {
    currentMonth.value = currentMonth.value.clone().subtract(1, 'month');
}

function nextMonth() {
    currentMonth.value = currentMonth.value.clone().add(1, 'month');
}

// Add date selection handlers
function handleDateClick(day) {
    if (!selecting.value) {
        selecting.value = true;
        tempStartDate.value = day.date;
        tempEndDate.value = day.date;
    } else {
        selecting.value = false;
        emit('update:modelValue', {
            preset: 'custom',
            startDate: moment.min(tempStartDate.value, day.date).format('YYYY-MM-DD'),
            endDate: moment.max(tempStartDate.value, day.date).format('YYYY-MM-DD')
        });
        tempStartDate.value = null;
        tempEndDate.value = null;
        showDatepicker.value = false;
    }
}

function handleDateMouseOver(day) {
    if (selecting.value) {
        tempEndDate.value = day.date;
    }
}

// Click outside handlers
document.addEventListener('mousedown', (event) => {
    if (open.value && !selectView.value?.contains(event.target) && !selectButton.value?.contains(event.target)) {
        open.value = false;
    }
    if (showDatepicker.value && !datepickerView.value?.contains(event.target) && !datepickerButton.value?.contains(event.target)) {
        showDatepicker.value = false;
    }
});

// Update the openSelect function
function openSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    open.value = !open.value;
}

// Add mounted hook to set initial state if none exists
onMounted(() => {
    if (!props.modelValue.preset) {
        emit('update:modelValue', {
            preset: 'last7',
            startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
        });
    }
});

const getDayClasses = (day) => {
    return [
        'text-sm w-full h-9 flex items-center justify-center mx-auto cursor-pointer',
        // Current date styling
        moment().isSame(day.date, 'day') ? 'font-semibold text-hbz' : '',
        // Start date styling
        day.isStart ? 'bg-hbz text-white rounded-l-md hover:bg-hb' : '',
        // End date styling
        day.isEnd ? 'bg-hbz text-white rounded-r-md hover:bg-hb' : '',
        // Range styling
        !day.isStart && !day.isEnd && day.isInRange ? 'bg-gray-200' : '',
        // Normal day styling
        !day.isCurrentMonth ? 'text-gray-400' : 'text-gray-900',
        // Hover effect
        'hover:bg-gray-100',
    ];
};
</script>

<template>
    <div class="flex flex-row gap-0 bg-white rounded-lg items-center relative">
        <!-- Select Button -->
        <button @click="openSelect" ref="selectButton" type="button" :class="[error ? 'border-red-500' : 'border-gray-300', open ? 'border-kr! border-2' : '']" class="h-9 flex items-center focus:ring-0 justify-between p-2 border rounded-l-md shadow-xs text-sm font-medium text-black bg-white duration-200 sm:min-w-44">
            <span class="truncate text-sm leading-6" :class="modelValue.preset ? 'text-gray-900' : 'text-gray-400'">
                {{ getDisplayText }}
            </span>
            <div class="flex ml-1">
                <font-awesome-icon :icon="['far', 'exclamation-triangle']" v-if="error" class="h-4 w-4 text-red-600 mr-2 justify-end" />
                <font-awesome-icon :icon="['far', 'chevron-down']" class="h-4 w-4 transition-transform duration-300 ease-in-out" :class="open ? '-rotate-180' : ''" />
            </div>
        </button>

        <!-- Select Dropdown -->
        <div v-if="open" ref="selectView" class="absolute left-0 mt-1 rounded-md bg-white shadow-lg z-10 border min-w-44 w-full" :style="style">
            <ul tabindex="-1" role="listbox" class="max-h-64 rounded-md p-2 text-base leading-6 shadow-2xs overflow-auto focus:outline-hidden sm:text-sm sm:leading-5 scrollbar-hide">
                <li v-for="option in options" :key="option[valueId]" @click="setSelected(option[valueId])" class="cursor-default select-none relative py-1 hover:text-krz hover:cursor-pointer" :class="modelValue.preset === option[valueId] ? 'text-kr' : ''">
                    <span class="block truncate">{{ option[valueName] }}</span>
                </li>
            </ul>
        </div>

        <!-- Datepicker Button -->
        <button @click="showDatepicker = !showDatepicker" ref="datepickerButton" class="h-9 flex items-center focus:ring-0 justify-between p-2 text-sm font-medium text-black bg-white duration-200 border-l border-gray-300 rounded-r-md">
            <font-awesome-icon :icon="['far', 'calendar']" class="h-6 w-6 text-black"/>
        </button>

        <!-- Calendar Dropdown -->
        <div v-if="showDatepicker" ref="datepickerView" class="absolute left-0 top-full mt-1 p-4 rounded-md bg-white shadow-lg z-20 border">
            <!-- Calendar content -->
            <div class="w-72">
                <div class="flex items-center justify-between mb-4">
                    <button @click="prevMonth" class="p-1 hover:bg-gray-100 rounded">
                        <font-awesome-icon :icon="['far', 'chevron-left']" class="h-4 w-4" />
                    </button>
                    <span class="text-sm font-medium">{{ currentMonth.format('MMMM YYYY') }}</span>
                    <button @click="nextMonth" class="p-1 hover:bg-gray-100 rounded">
                        <font-awesome-icon :icon="['far', 'chevron-right']" class="h-4 w-4" />
                    </button>
                </div>

                <div class="grid grid-cols-7">
                    <div v-for="day in ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo']" :key="day" class="text-center text-xs font-medium text-gray-500 my-2">
                        {{ day }}
                    </div>

                    <div v-for="day in daysToDisplay" 
                        :key="day.date.format('YYYY-MM-DD')" 
                        @click="handleDateClick(day)"
                        @mouseover="handleDateMouseOver(day)"
                        class="text-center py-1">
                        <div :class="getDayClasses(day)">
                            {{ day.date.format('D') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

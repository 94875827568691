import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import * as Sentry from "@sentry/vue";

import {
    faEnvelope,
    faPhone,
    faMagnifyingGlass,
    faTriangleExclamation,
    faEye,
    faXmark,
    faGear,
    faEyeSlash,
    faTrash,
    faArrowsMaximize,
    faCopy,
    faKey,
    faArrowDownToBracket,
    faCircleExclamation,
    faInbox,
    faCircleInfo,
    faPenRuler,
    faCircleCheck,
    faChevronDown,
    faEllipsis,
    faMapLocationDot,
    faLocationDot,
    faBars,
    faBell,
    faPlus,
    faPenToSquare,
    faClone,
    faBoxArchive,
    faPaperPlane,
    faFolder,
    faFolderOpen,
    faFloppyDisk,
    faCloudArrowUp,
    faMinus,
    faPaperclip,
    faChevronLeft,
    faCalendar,
    faChevronRight,
    faImage,
    faDownload,
    faGauge,
    faBolt,
    faFire,
    faDroplet,
    faTemperatureArrowUp,
    faCardsBlank,
    faLeaf,
    faCar,
    faSignature,
    faDoorClosed,
    faAddressCard,
    faCalculator,
    faChevronUp,
    faGridHorizontal,  
    faClock,          
    faUsers,
    faUser,          
    faFilePlus,
    faClipboardListCheck
} from "@fortawesome/pro-regular-svg-icons";

import {
    faSpinner as fasSpinnerSolid,
    faXmark as fasXmark,
} from "@fortawesome/pro-solid-svg-icons";

import Layout from "./Pages/Layout/Layout.vue";

createInertiaApp({
    title: (title) => `${title} - Website`,
    resolve: (name) => {
        const pages = import.meta.glob(
            ["./Pages/*.vue", "./Pages/**/*.vue", "./Pages/**/**/*.vue"],
            { eager: true }
        );
        let page = pages[`./Pages/${name}.vue`];
        if (page && page.default) {
            page.default.layout = Layout;
            return page.default;
        } else if (page) {
            page.layout = Layout;
            return page;
        }
        throw new Error(`Page not found: ${name}`);
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) }).use(plugin);
        library.add(
            faEnvelope,
            faPhone,
            faMagnifyingGlass,
            faTriangleExclamation,
            faEye,
            faXmark,
            faPenToSquare,
            faClone,
            faBoxArchive,
            faPaperPlane,
            faPenRuler,
            faEllipsis,
            faGear,
            faEyeSlash,
            fasSpinnerSolid,
            faTrash,
            faArrowsMaximize,
            faCopy,
            faKey,
            faArrowDownToBracket,
            faInbox,
            faCircleExclamation,
            faCircleInfo,
            faCircleCheck,
            faChevronDown,
            faMapLocationDot,
            faLocationDot,
            fasXmark,
            faBars,
            faBell,
            faPlus,
            faFolder,
            faFolderOpen,
            faFloppyDisk,
            faCloudArrowUp,
            faMinus,
            faPaperclip,
            faChevronLeft,
            faCalendar,
            faChevronRight,
            faImage,
            faDownload,
            faGauge,
            faBolt,
            faFire,
            faDroplet,
            faTemperatureArrowUp,
            faCardsBlank,
            faLeaf,
            faCar,
            faSignature,
            faDoorClosed,
            faAddressCard,
            faCalculator,
            faChevronUp,            
            faGridHorizontal,
            faClock,
            faUsers,
            faUser,
            faFilePlus,
            faClipboardListCheck
        );

        Sentry.init({
            app,
            dsn: "https://cd5a6b1f37d29d6c0e2c60c8bfe58aaa@o4508357877039104.ingest.de.sentry.io/4508919281746000",
            integrations: [],
        });

        app.use(autoAnimatePlugin);
        app.component("font-awesome-icon", FontAwesomeIcon);

        app.mount(el);
    },
});

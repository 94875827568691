<script setup>
import { Link, Head } from '@inertiajs/vue3';

const props = defineProps({
    status: Number
})

const statusMessages = {
    404: {
        title: 'De pagina die je zoekt is uit de baan geslagen',
        message: 'We konden de pagina die je zocht niet vinden.'
    },
    500: {
        title: 'Er is iets misgegaan',
        message: 'Er is iets misgegaan op de server. Probeer het later opnieuw.'
    },
    503: {
        title: 'De server is niet beschikbaar',
        message: 'De server is tijdelijk niet beschikbaar. Probeer het later opnieuw.'
    }
}

</script>

<template>

    <Head title="Pagina niet gevonden" />

    <section class="pt-8">
        <div class="max-w-lg">
            <p class="text-2xl font-semibold leading-8 text-[#B42423]">{{ props.status }}</p>
            <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{{
                statusMessages[props.status].title }}</h1>
            <p class="mt-6 text-base leading-7 text-gray-600">{{ statusMessages[props.status].message }}</p>
            <div class="mt-10">
                <Link href="/" class="text-sm font-semibold leading-7 text-[#B42423]">
                <font-awesome-icon :icon="['far', 'arrow-left']" /> Terug naar Hoofdpagina</Link>
            </div>
        </div>
    </section>
</template>
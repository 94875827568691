<script setup>
/*
* This is a simple input component that can be used in forms.
* It accepts a label, error message, input ID, input type, and whether the input is required.
* The input is styled with Tailwind CSS.
* When an error is present, a red border is displayed around the input and a red exclamation triangle is displayed to the right of the input.
*
* @param {String} label - The label for the input.
* @param {String} error - The error message to display.
* @param {String} inputId - The ID for the input.
* @param {String} type - The type of input.
* @param {Boolean} required - Whether the input is required.
*/


import { ref, watch } from 'vue';

const model = defineModel();

const props = defineProps({
    label: String,
    error: String,
    form: Object,
    inputId: String,
    placeholder: {
        type: String,
        default: null,
    },
    type: {
        type: String,
        default: 'text',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    maxLength: {
        type: Number,
        default: 255,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
    rounded: {
        type: String,
        default: 'md', // Default rounded value (md = medium)
    },
    min: {
        type: Number,
        default: null,
    },
});

const input = ref(null);

//if error is present, focus on the input
watch(() => props.error, (value) => {
    error.value = value;
}, { deep: true });

const error = ref(props.error);

const showingPassword = ref(false);

function showPassword() {
    showingPassword.value = true;
    input.value.type = 'text';
}

function hidePassword() {
    showingPassword.value = false;
    input.value.type = 'password';
}

</script>

<template>
    <div v-auto-animate>
        <label v-if="label" :for="inputId" class="block text-sm font-semibold text-gray-900">{{ label }}</label>

        <div :class="[label ? 'mt-2 relative' : 'relative']">

            <!-- <font-awesome-icon v-if="type == 'email'" :icon="['far', 'envelope']"
                class="absolute pointer-events-none left-3 top-1/2 transform -translate-y-1/2" aria-hidden="true" />

            <font-awesome-icon v-if="type == 'tel'" :icon="['far', 'phone']"
                class="absolute pointer-events-none left-3 top-1/2 transform -translate-y-1/2" aria-hidden="true" /> -->

            <font-awesome-icon v-if="type == 'search'" :icon="['far', 'magnifying-glass']"
                class="absolute pointer-events-none left-3 top-1/2 transform -translate-y-1/2" aria-hidden="true" />

            <input v-model="model" ref="input" :id="inputId" :type="type" :autocomplete="inputId" :name="inputId" 
                :class="[error ? 'ring-red-500! pr-8' : '',
            // type == 'email' ? 'pl-9' : '',
            // type == 'tel' ? 'pl-9' : '',
            type == 'search' ? 'pl-9' : '',
            disabled ? 'ring-gray-300/50' : 'ring-gray-300',
            `rounded-${rounded}` // Use the rounded prop to set border radius
            ]" :maxlength="maxLength" :required="required" :placeholder="placeholder" :readonly="readOnly" :disabled="disabled" :min="min"
                class="block w-full border-0 py-1.5 text-gray-900 shadow-xs duration-200 placeholder:text-gray-400 ring-1 focus:ring-2 focus:ring-krz! sm:text-sm sm:leading-6" />

            <font-awesome-icon v-if="type == 'password' && !showingPassword" @click="showPassword" :icon="['far', 'eye']"
                class="absolute cursor-pointer right-3 top-1/2 transform -translate-y-1/2 text-krz"
                aria-hidden="true" />

            <font-awesome-icon v-if="type == 'password' && showingPassword" @click="hidePassword"
                :icon="['far', 'eye-slash']"
                class="absolute cursor-pointer right-3 top-1/2 transform -translate-y-1/2 text-krz"
                aria-hidden="true" />

            <font-awesome-icon v-if="error && type !== 'password'" :icon="['far', 'triangle-exclamation']"
                class="absolute pointer-events-none right-3 top-1/2 transform -translate-y-1/2 text-red-500"
                aria-hidden="true" />

            <font-awesome-icon v-if="error && type === 'password'" :icon="['far', 'triangle-exclamation']"
                class="absolute pointer-events-none right-10 top-1/2 transform -translate-y-1/2 text-red-500"
                aria-hidden="true" />
        </div>

        <p class="mt-2 text-sm text-red-600" v-if="error">
            {{ error }}
        </p>
    </div>
   
</template>

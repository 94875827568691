<script setup>
import { computed, ref, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import Notifications from '@/Components/Notifications.vue';

const page = usePage();
const notificationList = ref(null);
const toasts = computed(() => page.props.toasts);
watch(toasts, function (toasts) {
  toasts.forEach(message => {
    notificationList.value.add({title: message.title, text: message.body, type: message.severity});
  })
})
</script>

<template>
    <Notifications ref="notificationList" />
    <slot />
</template>
<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import { ref, computed } from 'vue';
import Select from '@/Components/Form/Select.vue';
import Button from '@/Components/Button.vue';
import Input from '@/Components/Form/Input.vue';
import axios from 'axios';
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import Notifications from '@/Components/Notifications.vue';
import DeleteDialog from '@/Components/DeleteDialog.vue';

const notificationList = ref([]);
const deleteDialog = ref(null);

const props = defineProps({
    rooms: Array
});

const rooms = ref(formatRooms(props.rooms));

function formatRooms(rooms) {
    return rooms.map(room => {
        return {
            ...room,
            isVisible: true,
            isFolderOpen: true,
            editable: false,
            listItems: room.elements.map(item => ({ ...item, editable: false, errors: {} })),
            errors: {}
        }
    });
}

const newRoomForm = useForm({
    name: '',
    type: 'self',
});

function submitNewRoomForm() {
    axios.post('/formulieren/settings/room', newRoomForm.data()).then((response) => {
        openRoomModal.value = false;
        newRoomForm.reset();

        //get the new room from the response and add it to the rooms array
        const newRoom = response.data.room;

        rooms.value.push({
            ...newRoom,
            isVisible: true,
            isFolderOpen: true,
            editable: false,
            listItems: newRoom.elements.map(item => ({ ...item, editable: false }))
        });

        notificationList.value.add({
            type: 'success',
            title: 'Success',
            text: 'Ruimte is toegevoegd.'
        })
    }).catch(error => {
        if (error.response.status === 422) {
            // Set errors for each field
            Object.keys(error.response.data.errors).forEach(field => {
                newRoomForm.errors[field] = error.response.data.errors[field][0];
            });
        } else {
            console.error(error);
        }
    });
}

const newElementsForm = useForm({
    names: [
        ""
    ],
    room_id: null
})

newElementsForm.errors.names = [""];

function submitNewElementsForm() {
    axios.post('/formulieren/settings/room-element', newElementsForm.data()).then((response) => {
        openElementModal.value = false;
        newElementsForm.reset();

        //get the new elements from the response and add them to the rooms array
        const newElements = response.data.elements;

        var room = rooms.value.find(room => room.id === response.data.room_id);

        for (let i = 0; i < newElements.length; i++) {
            room.listItems.push({ ...newElements[i], editable: false });
        }

        notificationList.value.add({
            type: 'success',
            title: 'Success',
            text: 'Elementen zijn toegevoegd.'
        })
    }).catch(error => {
        if (error.response.status === 422) {
            // Set errors for each field
            Object.keys(error.response.data.errors).forEach(field => {
                newElementsForm.errors[field] = error.response.data.errors[field];
            });

            console.log(newElementsForm.errors);
        } else {
            console.error(error);
        }
    });
}

function openNewElementModal(roomId) {
    openElementModal.value = true;

    newElementsForm.room_id = roomId;
}

const activeTab = ref(0);

const setActiveTab = (index) => {
    activeTab.value = index;
    switch (index) {
        case 1:
            rooms.value = rooms.value.map(room => ({ ...room, isVisible: room.type === 'self' }));
            break;
        case 2:
            rooms.value = rooms.value.map(room => ({ ...room, isVisible: room.type === 'group' }));
            break;
        case 3:
            rooms.value = rooms.value.map(room => ({ ...room, isVisible: room.type === 'all' }));
            break;
        default:
            rooms.value = rooms.value.map(room => ({ ...room, isVisible: true }));
            break;
    }
};

const categoryCount = computed(() => {
    const counts = {
        all: 0,
        zelfstandig: 0,
        onzelfstandig: 0,
        gecombineerd: 0,
    };
    rooms.value.forEach(room => {
        counts.all++;
        if (room.type === 'self') counts.zelfstandig++;
        if (room.type === 'group') counts.onzelfstandig++;
        if (room.type === 'all') counts.gecombineerd++;
    });
    return counts;
});

const categoryOptions = computed(() => [
    { id: 0, name: `Alle <span class="text-black/30">(${categoryCount.value.all})` },
    { id: 1, name: `Zelfstandig <span class="text-black/30">(${categoryCount.value.zelfstandig})` },
    { id: 2, name: `Onzelfstandig <span class="text-black/30">(${categoryCount.value.onzelfstandig})` },
    { id: 3, name: `Gecombineerd <span class="text-black/30">(${categoryCount.value.gecombineerd})` },
]);

const resetNewRoomFields = () => {
    newRoomForm.name = '';
    newRoomForm.type = 'self';
};

const addElementField = () => {
    newElementsForm.names.push('');
    newElementsForm.errors.names.push('');
};

const removeElementField = (index) => {
    newElementsForm.names.splice(index, 1);
    newElementsForm.errors.names.splice(index, 1);
};

const resetNewElementFields = () => {
    newElementsForm.names = [''];
    newElementsForm.errors.names = [''];
};

const toggleRoomFolder = (index) => {
    rooms.value[index].isFolderOpen = !rooms.value[index].isFolderOpen;
};

const toggleEditableInRoom = (roomId, listId) => {
    const roomIndex = rooms.value.findIndex(room => room.id === roomId);
    const itemIndex = rooms.value[roomIndex].listItems.findIndex(item => item.id === listId);
    rooms.value[roomIndex].listItems[itemIndex].editable = !rooms.value[roomIndex].listItems[itemIndex].editable;

    // Reset errors
    rooms.value[roomIndex].listItems[itemIndex].errors = {};
};

const saveListItemInRoom = (roomId, listId) => {
    const roomIndex = rooms.value.findIndex(room => room.id === roomId);
    const itemIndex = rooms.value[roomIndex].listItems.findIndex(item => item.id === listId);
    const newName = rooms.value[roomIndex].listItems[itemIndex].name;

    axios.put(`/formulieren/settings/room-element/${listId}`, { name: newName }).then(() => {
        rooms.value[roomIndex].listItems[itemIndex].editable = false;
        notificationList.value.add({
            type: 'success',
            title: 'Success',
            text: 'Element is aangepast.'
        })
    }).catch(error => {
        if (error.response.status === 422) {
            // Set errors for each field
            Object.keys(error.response.data.errors).forEach(field => {
                rooms.value[roomIndex].listItems[itemIndex].errors = {
                    name: error.response.data.errors[field][0]
                };
            });
        } else {
            console.error(error);
        }
    });
};

function toggleRoomEditable(roomId) {
    const index = rooms.value.findIndex(room => room.id === roomId);
    rooms.value[index].editable = !rooms.value[index].editable;

    // Reset errors
    rooms.value[index].errors = {};
}

function saveRoom(roomId) {
    const index = rooms.value.findIndex(room => room.id === roomId);
    const newName = rooms.value[index].name;
    const duplicate = rooms.value.some((room, i) => i !== index && room.name === newName);
    if (duplicate) {
        //set the error
        notificationList.value.add({
            type: 'error',
            title: 'Error',
            text: 'Deze ruimte naam is al in gebruik. Kies een andere naam.'
        })
    } else {
        axios.put(`/formulieren/settings/room/${roomId}`, { name: newName, type: rooms.value[index].type }).then(() => {
            rooms.value[index].editable = false;
            notificationList.value.add({
                type: 'success',
                title: 'Success',
                text: 'Ruimte is aangepast.'
            })
        }).catch(error => {
            if (error.response.status === 422) {
                // Set errors for each field
                Object.keys(error.response.data.errors).forEach(field => {
                    rooms.value[index].errors = {
                        name: error.response.data.errors[field][0]
                    };
                });
            } else {
                console.error(error);
            }
        });
    }
}

const toggleAllFolders = () => {
    const allOpen = rooms.value.every(room => room.isFolderOpen);
    rooms.value.forEach(room => {
        room.isFolderOpen = !allOpen;
    });
};

const allFoldersOpen = computed(() => {
    return rooms.value.every(room => room.isFolderOpen);
});

const statusOptions = [
    { id: 'self', name: 'Zelfstandig' },
    { id: 'group', name: 'Onzelfstandig' },
    { id: 'all', name: 'Gecombineerd' },
];

const sortOption = ref('dateAdded');
const sortOptions = [
    { id: 'dateAdded', name: 'Datum toegevoegd' },
    { id: 'alphabetical', name: 'Alfabetisch oplopend' },
    { id: 'reverseAlphabetical', name: 'Alfabetisch aflopend' },
];
const sortRooms = () => {
    if (sortOption.value === 'alphabetical') {
        rooms.value.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortOption.value === 'reverseAlphabetical') {
        rooms.value.sort((a, b) => b.name.localeCompare(a.name));
    } else {
        rooms.value.sort((a, b) => a.id - b.id);
    }
};

const openRoomModal = ref(false);

function openNewRoomModal() {
    openRoomModal.value = true;
    newRoomForm.errors.name = '';
}

const openElementModal = ref(false);

function openRoomDeleteDialog(roomId) {
    var room = rooms.value.find(room => room.id === roomId);
    deleteDialog.value.open("Verwijderen", `Je staat op het punt om ruimte ${room.name} te verwijderen. Weet je zeker dat je dit wilt doen?`, '/formulieren/settings/room/' + roomId, true, () => {
        rooms.value = rooms.value.filter(room => room.id !== roomId);
    });
}

function openElementDeleteDialog(roomId, elementId) {
    var room = rooms.value.find(room => room.id === roomId);
    var element = room.listItems.find(item => item.id === elementId);
    deleteDialog.value.open("Verwijderen", `Je staat op het punt om element ${element.name} te verwijderen. Weet je zeker dat je dit wilt doen?`, '/formulieren/settings/room-element/' + elementId, true, () => {
        room.listItems = room.listItems.filter(item => item.id !== elementId);
    });
}
</script>

<template>
    <Head title="Instellingen" />
    <Notifications ref="notificationList" :notifications="[]" />

    <div class="flex items-center flex-col">
        <div class="max-w-screen-2xl w-full h-auto md:h-24 lg:h-28 px-5 py-4 relative flex items-center md:justify-center">
            <img src="/public/img/logo.svg" alt="" class="absolute left-0 h-full w-auto p-4">
            <div class="ml-16 sm:ml-20 md:ml-0 flex flex-row gap-0 items-center">
                <Button type="href" :href="'/formulieren'" class="!shadow-none h-7 w-7 sm:h-8 sm:w-8 md:w-10 md:h-10 lg:w-14 lg:h-14 xl:w-16 xl:h-16">
                    <font-awesome-icon :icon="['far', 'chevron-left']" class="text-white h-6 w-6 sm:scale-105 md:scale-125 lg:scale-150 xl:scale-200" />
                </Button>
                <h1 class="text-base md:text-2xl lg:text-5xl font-bold text-white text-nowrap">Formulieren instellingen</h1>
            </div>
            <div class="absolute 2xl:hidden right-0 p-3 lg:pr-8 xl:pr-16 flex flex-row items-center gap-2">
                <Button class="bg-white hover:bg-gray-100 h-9 w-9 p-1.5" @click="toggleAllFolders">
                    <font-awesome-icon :icon="['far', allFoldersOpen ? 'folder-open' : 'folder']" class="text-black h-6 w-6" />
                </Button>
                <Button class="bg-krz hover:bg-kr h-9 w-auto" @click="openNewRoomModal">
                    <font-awesome-icon :icon="['far', 'plus']" class="text-white h-6 w-6" />
                    <p class="hidden md:block font-semibold text-white pr-1">Ruimte</p>
                </Button>
            </div>
        </div>
        <div class="max-w-screen-2xl w-full flex-1 px-3 md:px-6 lg:px-8 xl:px-16 flex flex-row justify-between items-center">
            <div class="flex flex-row rounded-md gap-2">
                <div class="sm:w-44 lg:hidden">
                    <Select v-model="activeTab" :options="categoryOptions" valueName="name" valueId="id" placeholder="Alle" @update:modelValue="setActiveTab($event)" />
                </div>
                <div class="hidden lg:flex flex-row bg-gray-200 p-1 gap-1 rounded-md">
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer" :class="{ 'bg-white': activeTab === 0 }" @click="setActiveTab(0)">
                        <p class="font-light">Alle</p>
                        <p class="text-black/30">{{ categoryCount.all }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer" :class="{ 'bg-white': activeTab === 1 }" @click="setActiveTab(1)">
                        <p class="font-light">Zelfstandig</p>
                        <p class="text-black/30">{{ categoryCount.zelfstandig }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer" :class="{ 'bg-white': activeTab === 2 }" @click="setActiveTab(2)">
                        <p class="font-light">Onzelfstandig</p>
                        <p class="text-black/30">{{ categoryCount.onzelfstandig }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer" :class="{ 'bg-white': activeTab === 3 }" @click="setActiveTab(3)">
                        <p class="font-light">Gecombineerd</p>
                        <p class="text-black/30">{{ categoryCount.gecombineerd }}</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-row items-center gap-2">
                <div class="w-48">
                    <Select v-model="sortOption" :options="sortOptions" valueName="name" valueId="id" class="w-full" @change="sortRooms" />
                </div>
                <Button class="hidden 2xl:flex bg-white hover:bg-gray-100 h-9 w-9 p-1.5" @click="toggleAllFolders">
                    <font-awesome-icon :icon="['far', allFoldersOpen ? 'folder-open' : 'folder']" class="text-black h-6 w-6" />
                </Button>
                <Button class="hidden 2xl:flex bg-krz hover:bg-kr h-9 w-auto" @click="openNewRoomModal">
                    <font-awesome-icon :icon="['far', 'plus']" class="text-white h-6 w-6" />
                    <p class="font-semibold text-white pr-1">Ruimte</p>
                </Button>
            </div>
        </div>
    </div>

    <div class="flex justify-center">
        <div class="max-w-screen-2xl relative w-full px-3 md:px-6 lg:px-8 xl:px-16" v-auto-animate>
            <p v-if="rooms.length === 0" class="my-2 md:my-4 text-white">Er zijn geen ruimtes aangemaakt.</p>
            <div v-else v-for="(room, roomIndex) in rooms" :key="room.id" v-show="room.isVisible" class="my-2 md:my-4 bg-white rounded-lg p-2">
                <div class="flex items-start justify-between gap-10">
                    <div v-if="room.editable" class="flex flex-row flex-wrap sm:flex-nowrap gap-1 items-baseline w-full">
                        <Input v-model="room.name" :inputId="'room-name-' + room.id" :placeholder="'Ruimte naam'" :disabled="!room.editable" class="sm:w-full max-w-96" />
                        <Select v-model="room.type" :options="statusOptions" valueName="name" valueId="id" />
                    </div>
                    <div v-else class="flex flex-col sm:flex-row gap-x-2 items-baseline w-full">
                        <p class="text-lg sm:text-xl md:text-2xl font-medium">{{ room.name }}</p>
                        <p class="text-sm sm:text-base md:text-lg font-light text-black/30">{{ statusOptions.find(status => status.id == room.type).name }}</p>
                    </div>
                    <div class="flex flex-row items-center gap-2 ml-auto">
                        <Button class="bg-white hover:bg-gray-100 border-[1px] h-9 w-9" @click="room.editable ? saveRoom(room.id) : toggleRoomEditable(room.id)">
                            <font-awesome-icon :icon="['far', room.editable ? 'floppy-disk' : 'pen-to-square']" class="text-black hover:text-krz" />
                        </Button>
                        <Button class="bg-white hover:bg-gray-100 border-[1px] h-9 w-9" @click="openRoomDeleteDialog(room.id)">
                            <font-awesome-icon :icon="['far', 'trash']" class="text-black h-6 w-6" />
                        </Button>
                        <Button class="bg-white hover:bg-gray-100 border-[1px] h-9 w-9" @click="toggleRoomFolder(roomIndex)">
                            <font-awesome-icon :icon="['far', room.isFolderOpen ? 'folder-open' : 'folder']" class="text-black h-6 w-6" />
                        </Button>
                    </div>
                </div>
                <div v-auto-animate>
                    <ul v-if="room.isFolderOpen" class="divide-y divide-gray-200" v-auto-animate>
                        <li v-for="(item, itemIndex) in room.listItems" :key="item.id">
                            <div class="flex flex-row justify-between items-center">
                                <template v-if="item.editable">
                                    <Input v-model="item.name" :inputId="'name-' + item.id" :placeholder="'Element naam'" :disabled="!item.editable" class="w-full" :error="item.errors.name" />
                                </template>
                                <template v-else>
                                    <p class="text-base font-light">{{ item.name }}</p>
                                </template>
                                <div class="py-3 flex flex-row items-center">
                                    <Button class="!shadow-none" @click="openElementDeleteDialog(room.id, item.id)">
                                        <font-awesome-icon :icon="['far', 'trash']" class="text-black hover:text-krz" />
                                    </Button>
                                    <Button class="!shadow-none" @click="item.editable ? saveListItemInRoom(room.id, item.id) : toggleEditableInRoom(room.id, item.id)">
                                        <font-awesome-icon :icon="['far', item.editable ? 'floppy-disk' : 'pen-to-square']" class="text-black hover:text-krz" />
                                    </Button>
                                </div>
                            </div>
                        </li>
                        <div class="mt-2 flex w-full justify-end">
                            <Button v-if="room.isFolderOpen" class="bg-krz hover:bg-kr h-9 w-auto" @click="openNewElementModal(room.id)">
                                <font-awesome-icon :icon="['far', 'plus']" class="text-white h-6 w-6" />
                                <p class="hidden md:block font-semibold text-white pr-1">Element toevoegen</p>
                            </Button>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    
    <TransitionRoot :show="openRoomModal" as="template" @after-leave="resetNewRoomFields" appear>
        <Dialog class="relative z-10" @close="openRoomModal = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed top-0 left-0 right-0 bottom-0 bg-gray-500/25 transition-opacity" />
            </TransitionChild>

            <div class="fixed top-0 left-0 right-0 bottom-0 z-10 w-screen p-0 pt-16 sm:p-16 md:p-20">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                    <DialogPanel class="mx-auto h-full sm:h-auto max-h-full sm:max-w-xl transform overflow-hidden rounded-t-xl sm:rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all space-y-3 flex flex-col justify-between">
                        <form @submit.prevent="submitNewRoomForm">
                            <div class="space-y-3 h-full  px-3 pt-3 border-b-[1px] mb-4 pb-10">
                                <Input v-model="newRoomForm.name" :inputId="'new-room-name'" :placeholder="'Ruimte naam'" :label="'Naam'" :error="newRoomForm.errors.name" class="w-full" />
                                <Select v-model="newRoomForm.type" :options="statusOptions" valueName="name" valueId="id" :label="'Woningtype'" :error="newRoomForm.errors.type" class="w-full" />
                            </div>
                            <div class="gap-1 flex w-full justify-end px-3 pb-3">
                                <Button class="bg-white hover:bg-gray-100 border-[1px] h-9 w-auto" @click="openRoomModal = false">
                                    <p class="font-semibold text-black pr-1">Annuleer</p>
                                </Button>
                                <Button class="bg-krz hover:bg-kr h-9 w-auto" buttonType="submit">
                                    <p class="font-semibold text-white pr-1">Opslaan</p>
                                </Button>
                            </div>
                        </form>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <TransitionRoot :show="openElementModal" as="template" @after-leave="resetNewElementFields" appear>
        <Dialog class="relative z-10" @close="openElementModal = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed top-0 left-0 right-0 bottom-0 bg-gray-500/25 transition-opacity" />
            </TransitionChild>

            <div class="fixed sm:top-0 left-0 right-0 bottom-0 z-10 h-full w-screen overflow-y-auto p-0 pt-16 sm:p-16 md:p-20">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95" enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100" leave-to="opacity-0 scale-95">
                    <DialogPanel class="mx-auto h-full sm:h-auto max-h-full sm:max-w-xl transform overflow-hidden rounded-t-xl sm:rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all space-y-3 flex flex-col justify-between">
                        <form @submit.prevent="submitNewElementsForm">
                            <div class="space-y-3 h-full overflow-y-auto px-3 pt-3 border-b-[1px] mb-4 pb-4">
                                <div v-for="(field, index) in newElementsForm.names" :key="index" class="flex flex-row gap-2 border-b-[1px] pb-3">
                                    <Input v-model="newElementsForm.names[index]" :inputId="'new-element-name-' + index" :placeholder="'Element naam'" :label="'Benaming'" :error="newElementsForm.errors.names[index]" class="w-full" />
                                    <Button v-if="index > 0" class="!shadow-none mt-7" @click="() => removeElementField(index)">
                                        <font-awesome-icon :icon="['far', 'trash']" class="text-black hover:text-krz" />
                                    </Button>
                                </div>
                                <Button class="bg-krz hover:bg-kr h-9 w-auto" @click="addElementField">
                                    <p class="font-semibold text-white">Extra element</p>
                                </Button>
                            </div>
                            <div class="gap-1 flex w-full justify-end px-3 pb-3">
                                <Button class="bg-white hover:bg-gray-100 border-[1px] h-9 w-auto" @click="openElementModal = false">
                                    <p class="font-semibold text-black">Annuleer</p>
                                </Button>
                                <Button class="bg-krz hover:bg-kr h-9 w-auto" buttonType="submit">
                                    <p class="font-semibold text-white">Opslaan</p>
                                </Button>
                            </div>
                        </form>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <DeleteDialog ref="deleteDialog" />
</template>
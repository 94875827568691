<script setup>
/*
* This is a select component that can be used in forms.
* It accepts a label, error message, input ID and whether the input is required.
* The input is styled with Tailwind CSS.
* When an error is present, a red border is displayed around the input and a red exclamation triangle is displayed to the right of the input.
*
* @param {String} label - The label for the input.
* @param {String} error - The error message to display.
* @param {String} inputId - The ID for the input.
* @param {String} type - The type of input.
* @param {Boolean} required - Whether the input is required.
* @param {Boolean} multiple - Whether the input is a multiple select.
* @param {Array} options - The options for the select.
* @param {String} valueId - The ID for the value of the options.
* @param {String} valueName - The name for the value of the options.
* @param {Boolean} search - Whether the select should have a search input.
* @param {String} searchPlaceholder - The placeholder for the search input.
* @param {String} searchNoResults - The message to display when no results are found.
* @param {String} placeholder - The placeholder for the select.
*/

import { ref, watch, computed, onMounted } from 'vue';

const props = defineProps({
    label: String,
    error: String,
    inputId: String,
    required: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: false,
    },
    options: {
        type: Array,
        default: () => [],
    },
    valueId: {
        type: String,
        default: 'id',
    },
    valueName: {
        type: String,
        default: 'name',
    },
    search: {
        type: Boolean,
        default: false,
    },
    searchPlaceholder: {
        type: String,
        default: 'Zoeken...',
    },
    searchNoResults: {
        type: String,
        default: 'Geen resultaten gevonden',
    },
    placeholder: {
        type: String,
        default: 'Selecteer...',
    },
    readonly: {
        type: Boolean,
        default: false,
    },
    clear: {
        type: Boolean,
        default: false,
    }
})

const model = defineModel();

const options = ref(props.options);

const open = ref(false);

function openSelect() {
    if (props.readonly) return;
    
    open.value = !open.value;

    //clear search
    searchValue.value = '';
}

const style = computed(() => {
    if (!open.value) return;
    if (selectView.value == null || selectButton.value == null) return;

    //calculate the height of the selectView and determine if it should be above or below the button
    const selectButtonRect = selectButton.value.getBoundingClientRect();
    const selectViewRect = selectView.value.getBoundingClientRect();
    const spaceBelow = window.innerHeight - selectButtonRect.bottom;
    const spaceAbove = selectButtonRect.top;

    if (spaceBelow < selectViewRect.height && spaceAbove > selectViewRect.height) {
        return {
            bottom: '100%',
            top: 'auto',
        };
    } else {
        return {
            top: '100%',
            bottom: 'auto',
        };
    }
});

const emits = defineEmits(['change']);

function setSelected(value) {
    if (value == null) {
        model.value = null;
        open.value = false;
        return;
    }
    if (props.multiple) {
        if (!model.value.includes(value)) {
            model.value.push(value);
        } else {
            model.value = model.value.filter((item) => item !== value);
        }
    } else {
        model.value = value;
    }

    if (!props.multiple) {
        open.value = false;
    }

    emits('change', value);
}

function getSelected() {
    // if multiple, return the names of the selected options, the text needs to be truncated if it's too long
    if (props.multiple) {
        return model.value.map((value) => {
            const option = props.options.find((option) => option[props.valueId] === value);

            if (!option) return;
            return option[props.valueName];
        }).join(', ');
    }

    // if single, return the name of the selected option
    const option = props.options.find((option) => option[props.valueId] === model.value);

    return option ? option[props.valueName] : '';
}

const selectButton = ref(null);
const selectView = ref(null);

//when clicking outside the selectView, close the selectView
document.addEventListener('mousedown', (event) => {
    if (props.search) {
        if (open.value && !selectView.value.contains(event.target) && !selectButton.value.contains(event.target) && !event.target.classList.contains('fa-search')) {
            open.value = false;
        }
    } else {
        if (open.value && !selectView.value.contains(event.target) && !selectButton.value.contains(event.target)) {
            open.value = false;
        }
    }
});

const searchValue = ref('');

watch(() => searchValue.value, (value) => {
    if (value == "") {
        options.value = props.value;
    }
    //get the options that match the search value
    const searchOptions = props.options.filter((option) => option[props.valueName].toLowerCase().includes(value.toLowerCase()));

    //if there are no search options, show a message
    if (searchOptions.length === 0) {
        searchOptions.push({
            [props.valueId]: null,
            [props.valueName]: props.searchNoResults,
        });
    }

    //set the options to the search options
    options.value = searchOptions;
});

const error = ref(props.error);

//if error is present, focus on the input
watch(() => props.error, (value) => {
    error.value = value;
}, { deep: true });

watch(() => props.options, (value) => {
    options.value = value;
}, { deep: true });

onMounted(() => {
    if (!options.value) {
        options.value = [];
        options.value.push({
            [props.valueId]: null,
            [props.valueName]: props.searchNoResults,
        });
    } else if (options.value.length === 0) {
        options.value.push({
            [props.valueId]: null,
            [props.valueName]: props.searchNoResults,
        });
    }
});

</script>

<template>
    <div v-auto-animate>
        <label v-if="label" :for="inputId" class="block text-sm font-semibold text-gray-900">{{ label }}</label>

        <div :class="[label ? 'mt-2 relative' : 'relative']" v-auto-animate="{ duration: 150 }">
            <button @click="openSelect" ref="selectButton" type="button"
                :class="[
                    error ? 'border-red-500' : 'border-gray-300', 
                    open ? 'border-kr! border-2' : '',
                    readonly ? 'opacity-75 cursor-not-allowed' : 'hover:cursor-pointer'
                ]"
                class="h-9 w-full flex items-center focus:ring-0 justify-between p-2 border border-gray-300 rounded-md shadow-xs text-sm font-medium text-black bg-white duration-200">
                <span
                    :class="['truncate text-sm leading-6', (multiple ? model.length > 0 : model != null) ? 'text-gray-900' : 'text-gray-400']"
                    v-html="(multiple ? model.length > 0 : model != null) ? getSelected() : placeholder"></span>
                <div class="flex ml-1">
                    <font-awesome-icon :icon="['far', 'xmark']"
                        v-if="clear && (multiple ? model.length > 0 : model != null) && !readonly"
                        @click="multiple ? model = [] : model = null" class="h-4 w-4 text-gray-600 mr-2 justify-end" />
                    <font-awesome-icon :icon="['far', 'exclamation-triangle']" v-if="error"
                        class="h-4 w-4 text-red-600 mr-2 justify-end" />
                    <font-awesome-icon v-if="!readonly" :icon="['far', 'chevron-down']"
                        class="h-4 w-4 transition-transform duration-300 ease-in-out" :class="open ? '-rotate-180' : ''"
                        aria-hidden="true" />
                </div>
            </button>

            <div ref="selectView" v-if="open"
                class="absolute mt-1 rounded-md bg-white shadow-lg z-10 border w-auto sm:min-w-44" :style="style">
                <div class="relative" v-if="search">
                    <input v-model="searchValue" type="text" :placeholder="searchPlaceholder"
                        class="h-8 w-full p-2 pl-9 border border-gray-300 rounded-md shadow-xs text-sm focus:ring-0" />
                    <font-awesome-icon :icon="['far', 'search']"
                        class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <font-awesome-icon :icon="['far', 'xmark']"
                        class="absolute right-3 top-1/2 transform -translate-y-1/2 hover:cursor-pointer"
                        @click="searchValue = ''" v-if="searchValue.length > 0" />
                </div>

                <ul tabindex="-1" role="listbox" v-auto-animate
                    class="max-h-64 rounded-md p-2 text-base leading-6 shadow-2xs overflow-auto focus:outline-hidden sm:text-sm sm:leading-5 scrollbar-hide">
                    <li v-for="option in options" :key="option[valueId]" role="option"
                        @click="setSelected(option[valueId])"
                        :class="['cursor-default select-none relative py-1 hover:text-krz hover:cursor-pointer', (multiple ? model.includes(option[valueId]) : model === option[valueId]) ? 'text-kr' : '']">
                        <div class="flex" v-if="multiple">
                            <input type="checkbox" v-if="option[valueId] != null"
                                :checked="model.includes(option[valueId])"
                                class="rounded-sm border-gray-300 text-kr focus:ring-0! transition duration-150 ease-in-out">
                            <span v-html="option[valueName]" class="block truncate"></span>
                        </div>
                        <div class="flex" v-else>
                            <span v-html="option[valueName]" class="block truncate"></span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <p class="mt-2 text-sm text-red-600" v-if="error">
            {{ error }}
        </p>
    </div>

</template>

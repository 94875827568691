<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import axios from 'axios';
import { useForm } from '@inertiajs/vue3'
import SaveButton from '@/Components/Form/SaveButton.vue';

const deleteDialog = ref(false);
const deleteErrorDialog = ref(false);
const deleteDialogText = ref("");
const deleteDialogUrl = ref("");
const deleteDialogTitle = ref("");
const doAjax = ref(false);
const callBack = ref(null);

function open(title, text, url, ajax = false, callback = null) {
  deleteDialogTitle.value = title;
  deleteDialogText.value = text;
  deleteDialogUrl.value = url;
  deleteDialog.value = true;

  doAjax.value = ajax;

  if (callback) {
    callBack.value = callback;
  }
}

function close() {
  deleteDialog.value = false;
}

defineExpose({ open, close });

function submitDelete() {
  if (doAjax.value) {
    axios.delete(deleteDialogUrl.value).then((response) => {
      deleteDialog.value = false;
      if (callBack.value) {
        callBack.value(response)
      };
    }).catch((error) => {
      deleteDialog.value = false;
      //set error dialog to true after 300ms
      setTimeout(() => {
        deleteErrorDialog.value = true;
      }, 300);
    });
  } else {
    useForm({}).delete(deleteDialogUrl.value, {
      preserveScroll: false,
      onSuccess: (response) => {
        deleteDialog.value = false;
        if (callBack.value) {
          callBack.value();
        }
      },
      onError: (error) => {
        deleteDialog.value = false;
        //set error dialog to true after 300ms
        setTimeout(() => {
          deleteErrorDialog.value = true;
        }, 300);
      }
    })
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="deleteDialog">
    <Dialog as="div" class="relative z-40" @close="deleteDialog = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500/60 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <font-awesome-icon :icon="['far', 'triangle-exclamation']" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900"
                    v-text="deleteDialogTitle"></DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500" v-text="deleteDialogText"></p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
                <SaveButton :text="deleteDialogTitle" :savingText="'Aan het ' + deleteDialogTitle"
                  class="py-3 text-[15px] bg-red-600!" @click="submitDelete" />
                <button type="button"
                  class="mt-3 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto cursor-pointer"
                  @click="close" ref="cancelButtonRef">Annuleren</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot as="template" :show="deleteErrorDialog">
    <Dialog as="div" class="relative z-40" @close="deleteErrorDialog = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500/60 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <font-awesome-icon :icon="['far', 'triangle-exclamation']" />
                </div>
                <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Oeps!</DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">Er ging iets fout tijdens het verwijderen, probeer het a.u.b.
                      opnieuw of neem contact op met je systeembeheerder.</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-xs ring-1 ring-inset ring-gray-300 hover:bg-gray-50 duration-200 sm:mt-0 sm:w-auto"
                  @click="deleteErrorDialog = false;" ref="cancelButtonRef">Sluiten</button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup>
/*
* This is a simple checkbox component that can be used in forms.
* It accepts a label, error message, input ID and whether the input is required.
* The input is styled with Tailwind CSS.
* When an error is present, a red border is displayed around the input and a red exclamation triangle is displayed to the right of the input.
*
* @param {String} label - The label for the input.
* @param {String} error - The error message to display.
* @param {String} inputId - The ID for the input.
* @param {String} type - The type of input.
* @param {Boolean} required - Whether the input is required.
*/


import { onMounted, ref, watch } from 'vue';

const model = defineModel();

const props = defineProps({
    label: String,
    error: String,
    inputId: String,
    value: {
        type: String,
        default: null,
    },
    required: {
        type: Boolean,
        default: false,
    },
    hint: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
});

const input = ref(null);


//if error is present, focus on the input
watch(() => props.error, (value) => {
    error.value = value;
}, {deep:true});

const error = ref(props.error);
</script>

<template>
    <div class="flex gap-x-3">
        <div class="flex h-6 items-center">
            <input ref="input" :id="inputId" v-model="model" :name="inputId" type="checkbox" :required="required"
                class="h-4 w-4 rounded-sm border-gray-300 text-kr focus:ring-0! duration-200"
                :class="[error ? 'border-red-500!' : '', disabled ? 'border-gray-300/50' : 'border-gray-300']" 
                :value="value" :disabled="disabled" :readonly="readOnly"/>
        </div>
        <div class="text-sm leading-6">
            <label :for="inputId" class="font-medium text-gray-900" v-text="label" />
            <p class="text-gray-500" v-if="hint" v-text="hint" />
        </div>
    </div>

    <p class="mt-2 text-sm text-red-600" v-if="error">
        {{ error }}
    </p>
</template>

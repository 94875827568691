<script setup>
import Notification from "./Notification.vue"
import { ref } from 'vue'

const props = defineProps({ notifications: { type: Array, default: () => [] } })

const notifications = ref(props.notifications)

function add(object) {
    notifications.value.push(object)
}

defineExpose({ add })

</script>

<template>
    <div id="notifications">
        <div class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50 w-full">
            <div class="flex w-full flex-col justify-end space-y-4">
                <div v-auto-animate class="space-y-4 ml-auto w-full sm:w-96">
                    <Notification :notification="notification" v-for="notification in notifications" />
                </div>
            </div>
        </div>
    </div>
</template>

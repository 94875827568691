<script setup>
import { ref, onMounted, computed } from 'vue'

const show = ref(true);
const props = defineProps({ notification: Object });
const timeLeft = ref(5);
const intervalId = ref(null);

const progressWidth = computed(() => {
  return (timeLeft.value / 5) * 100 + '%';
});

function setRead() {
  show.value = false;
}

function startTimer() {
  timeLeft.value = 5; // Reset the time left
  intervalId.value = setInterval(() => {
    timeLeft.value -= 0.1;
    if (timeLeft.value <= 0) {
      clearInterval(intervalId.value);
      setRead();
    }
  }, 100);
}

function pauseTimer() {
  clearInterval(intervalId.value);
}

onMounted(() => {
  startTimer();
});
</script>

<template>
  <div v-if="show"
    class="pointer-events-auto w-full sm:max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-opacity-5"
    @mouseenter="pauseTimer" @mouseleave="startTimer">
    <div class="p-4">
      <div class="flex items-start">
        <div class="shrink-0">
          <font-awesome-icon :icon="['far', 'triangle-exclamation']" class="text-og h-5 w-5 shrink-0" v-if="notification.type == 'warning'" />
          <font-awesome-icon :icon="['far', 'circle-exclamation']" class="text-kr h-5 w-5 shrink-0" v-else-if="notification.type == 'error'" />
          <font-awesome-icon :icon="['far', 'circle-check']" class="text-ag h-5 w-5 shrink-0" v-else-if="notification.type == 'success'" />
          <font-awesome-icon :icon="['far', 'circle-info']" class="text-hb h-5 w-5 shrink-0" v-else />
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">{{ notification.title }}</p>
          <p class="mt-1 text-sm text-gray-500">{{ notification.text }}</p>
        </div>
        <div class="ml-4 flex shrink-0">
          <button type="button" @click="setRead"
            class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-0 cursor-pointer">
            <span class="sr-only">Sluiten</span>
            <font-awesome-icon :icon="['far', 'xmark']" class="text-black pt-2 shrink-0" />
          </button>
        </div>
      </div>
    </div>
    <div class="bg-gray-100 h-2">
      <div class="bg-kr h-2 transition-all duration-100 ease-linear" :style="{ width: progressWidth }" v-if="notification.type == 'error'"></div>
      <div class="bg-og h-2 transition-all duration-100 ease-linear" :style="{ width: progressWidth }" v-else-if="notification.type == 'warning'"></div>
      <div class="bg-ag h-2 transition-all duration-100 ease-linear" :style="{ width: progressWidth }" v-else-if="notification.type == 'success'"></div>
      <div class="bg-hb h-2 transition-all duration-100 ease-linear" :style="{ width: progressWidth }" v-else></div>
    </div>
  </div>
</template>
import { usePage } from '@inertiajs/vue3';

/**
 * Checks if the current user has a specific permission
 * @param {string} permission - The permission to check
 * @return {boolean} - True if user has permission, false otherwise
 */
export function hasPermission(permission) {
    try {
        const auth = usePage().props.auth.user;

        if (!auth?.role?.permissions) {
            return false;
        }
        
        return auth.role.permissions.some(perm => perm.name === permission);
    } catch (error) {
        console.error('Error checking permission:', error);
        return false;
    }
}
<script setup>
/**
 * Google Address component\
 * This component is used to search for an address using the Google Maps API
 * It will return the address in JSON format
 * 
 * THIS COMPONENT REQUIRES THE GOOGLEMAPSCONTROLLER TO WORK
 * 
 * @param {String} label - The label of the input field
 * @param {String} error - The error message to display
 * @param {String} inputId - The id of the input field
 * @param {Boolean} required - Whether the input field is required
 * @param {String} placeholder - The placeholder of the input field
 */

import { watch, ref, computed } from 'vue';
import axios from 'axios';

const props = defineProps({
    label: String,
    error: String,
    inputId: String,
    required: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: 'Begin te typen...',
    },
    showClear: {
        type: Boolean,
        default: false,
    }
});

const searchTimeout = 759;

const input = ref(null);

const model = defineModel();
const formatModel = ref(null);

const error = ref(props.error);

const loading = ref(false);

watch(() => props.error, (value) => {
    error.value = value;
}, { deep: true });

function formatResult(place) {
    const ADDRESS_COMPONENTS = {
        subpremise: 'short_name',
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'long_name',
        country: 'long_name',
        postal_code: 'short_name',
    };

    let returnData = {};
    for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0];

        if (ADDRESS_COMPONENTS[addressType]) {
            let val = place.address_components[i][ADDRESS_COMPONENTS[addressType]];
            returnData[addressType] = val;
        }
    }

    returnData['latitude'] = place.geometry.location.lat;
    returnData['longitude'] = place.geometry.location.lng;
    returnData['place_id'] = place.place_id;
    return returnData
}

function clear() {
    model.value = null;
    formatModel.value = null;
    autocompleteResults.value = [];
    autocompleteOpen.value = false;
}

function init() {
    const initial = model.value;

    if (initial && initial.place_id) {
        axios.get('/gmap/place', {
            params: {
                place_id: initial.place_id,
            }
        }).then((response) => {
            if (response.data.status === 'OK') {
                formatModel.value = response.data.result.formatted_address;
                model.value = formatResult(response.data.result);
            }
        }).catch((error) => {
            //
        });
    }
}

init();

const autocompleteResults = ref([]);

const abortControllers = ref([]);
const timeouts = ref([]);

function getAutocomplete(event) {
    if (event.target.value.length < 3) {
        autocompleteResults.value = [];
        autocompleteOpen.value = false;
        loading.value = false;
        return;
    }

    loading.value = true;
    autocompleteOpen.value = true;
    // Cancel all previous timeouts
    timeouts.value.forEach((timeout) => {
        clearTimeout(timeout);
    });

    const timeout = setTimeout(() => {
        getAutocompleteResult(event.target.value);
    }, searchTimeout);

    timeouts.value.push(timeout);
}

function getAutocompleteResult(inputValue) {
    var controller = new AbortController();
    abortControllers.value.push(controller);

    // Cancel all previous requests
    abortControllers.value.slice(0, -1).forEach((controller) => {
        controller.abort();
    });

    axios.get('/gmap/autocomplete', {
        params: {
            input: inputValue,
        },
        signal: controller.signal
    }).then((response) => {
        loading.value = false;

        //remove the controller from the runningAutocompletes array
        abortControllers.value = abortControllers.value.filter((c) => c !== controller);

        if (response.status == 200) {
            const results = response.data;
            if (results.length > 0) {
                autocompleteOpen.value = true;
                autocompleteResults.value = results;
            } else {
                autocompleteResults.value = [];
            }
        }
    }).catch((error) => {
        //
    })
}

function setPlace(place) {
    axios.get('/gmap/place', {
        params: {
            place_id: place.place_id,
        }
    }).then((response) => {
        if (response.status == 200) {
            formatModel.value = response.data.result.formatted_address;
            model.value = formatResult(response.data.result);
            autocompleteResults.value = [];
            autocompleteOpen.value = false;
        }
    }).catch((error) => {
    });
}

const autocompleteOpen = ref(false);
const autocompleteView = ref(null);

const style = computed(() => {
    if (!autocompleteOpen.value) return;
    if (autocompleteView.value == null || input.value == null) return;

    // Calculate the height of the autocompleteView and determine if it should be above or below the input
    const inputRect = input.value.getBoundingClientRect();
    const autocompleteViewRect = autocompleteView.value.getBoundingClientRect();
    const spaceBelow = window.innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;

    if (spaceBelow < autocompleteViewRect.height && spaceAbove > autocompleteViewRect.height) {
        return {
            bottom: '100%',
            top: 'auto',
        };
    } else {
        return {
            top: '100%',
            bottom: 'auto',
        };
    }
});

//when clicking outside the autocompleteView, close the autocompleteView
document.addEventListener('mousedown', (event) => {
    if (!autocompleteView.value) return;
    if (autocompleteOpen.value && !autocompleteView.value.contains(event.target) && !input.value.contains(event.target)) {
        autocompleteOpen.value = false;
        autocompleteResults.value = [];
        formatModel.value = null;
        model.value = null;
    }
});
</script>

<template>
    <div v-auto-animate>
        <label v-if="label" :for="inputId" class="block text-sm font-semibold text-gray-900">{{ label }}</label>

        <div :class="[label ? 'mt-2 relative' : 'relative']" v-auto-animate>

            <font-awesome-icon :icon="['far', 'map-location-dot']"
                class="absolute pointer-events-none left-3 top-1/2 transform -translate-y-1/2 text-gray-700 hover:cursor-pointer" />

            <input ref="input" :id="inputId" :name="inputId" :placeholder="placeholder" :required="required" :class="[
                error && showClear ? 'ring-red-500! pr-12' : '',
                error && !showClear ? 'ring-red-500! pr-9' : ''
            ]" class="pl-9 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-xs ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-kr! sm:text-sm sm:leading-6 duration-200"
                type="text" v-model="formatModel" @input="getAutocomplete" />

            <input type="hidden" v-model="model" />

            <font-awesome-icon v-if="showClear" :icon="['far', 'xmark']"
                class="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-700 hover:cursor-pointer"
                @click="clear" />

            <font-awesome-icon v-if="error" :icon="['far', 'exclamation-triangle']"
                :class="showClear ? 'right-7' : 'right-3'"
                class="absolute pointer-events-none top-1/2 transform -translate-y-1/2 text-red-500" />

            <div ref="autocompleteView" v-if="autocompleteOpen"
                class="absolute mt-1 rounded-md bg-white shadow-lg z-10 border w-full" :style="style" v-auto-animate>
                <ul tabindex="-1" role="listbox" v-if="autocompleteResults.length > 0 && !loading"
                    class="max-h-60 rounded-md py-1 text-base leading-6 shadow-2xs overflow-auto focus:outline-hidden sm:text-sm sm:leading-5">
                    <li v-for="place in autocompleteResults" role="option" @click="setPlace(place)"
                        class="cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-50 hover:cursor-pointer">
                        <font-awesome-icon :icon="['far', 'location-dot']"
                            class="absolute left-3 top-1/2 transform -translate-y-1/2 text-kr" />
                        <span class="block truncate pl-5">{{ place.description }}</span>
                    </li>
                </ul>
                <div v-else-if="!loading && autocompleteResults.length < 1" class="py-2 pl-3 pr-9">
                    <font-awesome-icon :icon="['fas', 'xmark']" class=" text-red-500 h-4 w-4 text-xl" />

                    <span class="pl-3 text-gray-900">Geen zoek resultaten gevonden</span>
                </div>
                <div v-else-if="loading" class="py-2 pl-3 pr-9">
                    <font-awesome-icon :icon="['fas', 'spinner']" class="animate-spin text-kr" />

                    <span class="pl-3 text-gray-900">Resultaten aan het ophalen...</span>
                </div>
            </div>
        </div>

        <div v-if="error" class="text-red-500 text-sm pt-1">{{ error }}</div>

    </div>

</template>
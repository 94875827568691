<script setup>
import { Head } from '@inertiajs/vue3';
import { ref, computed } from 'vue';
import Select from '@/Components/Form/Select.vue';
import Checkbox from '@/Components/Form/Checkbox.vue';
import Button from '@/Components/Button.vue';
import Input from '@/Components/Form/Input.vue';
import Textarea from '@/Components/Form/Textarea.vue';
import DatePicker from '@/Components/Form/DatePicker.vue';

const roomType = [
    { id: 'Zelfstandig', name: 'Zelfstandig' },
    { id: 'Onzelfstandig', name: 'Onzelfstandig' },
];

const roomConfigurations = ref({
    Zelfstandig: [
        { name: 'Woonkamer', elements: ['Bank', 'TV', 'Tafel'] },
        { name: 'Slaapkamer', elements: ['Bed', 'Kledingkast'] },
        { name: 'Keuken', elements: ['Aanrecht', 'Koelkast'] },
    ],
    Onzelfstandig: [
        { name: 'Woonkamer', elements: ['Bank', 'TV'] },
        { name: 'Slaapkamer', elements: ['Bed'] },
    ],
});

const newForm = ref({
    relatienummer: '',
    straatnaam: '',
    huisnummer: '',
    toevoeging: '',
    woningtype: '',
    postcode: '',
    opleveraar: '',
    opleverdatum: '',
    email: '',
    draft: false,
    comments: {},
    status: {},
    disabledRooms: {},
    disabledElements: {}
});

const formErrors = ref({
    relatienummer: '',
    straatnaam: '',
    huisnummer: '',
    woningtype: '',
    postcode: '',
    opleveraar: '',
    opleverdatum: '',
    email: '',
});

const selectedRooms = computed(() => {
    return roomConfigurations.value[newForm.value.woningtype] || [];
});

const validateForm = () => {
    formErrors.value = {
        relatienummer: '',
        straatnaam: '',
        huisnummer: '',
        woningtype: '',
        postcode: '',
        opleveraar: '',
        opleverdatum: '',
        email: '',
    };

    let hasErrors = false;

    if (!newForm.value.relatienummer) {
        formErrors.value.relatienummer = 'Relatienummer mag niet leeg zijn.';
        hasErrors = true;
    }
    if (!newForm.value.straatnaam) {
        formErrors.value.straatnaam = 'Straatnaam mag niet leeg zijn.';
        hasErrors = true;
    }
    if (!newForm.value.huisnummer) {
        formErrors.value.huisnummer = 'Huisnummer mag niet leeg zijn.';
        hasErrors = true;
    }
    if (!newForm.value.woningtype) {
        formErrors.value.woningtype = 'Woningtype mag niet leeg zijn.';
        hasErrors = true;
    }
    if (!newForm.value.postcode) {
        formErrors.value.postcode = 'Postcode mag niet leeg zijn.';
        hasErrors = true;
    } else if (!/^\d{4}\s*[A-Za-z]{2}$/.test(newForm.value.postcode)) {
        formErrors.value.postcode = 'Postcode is ongeldig. Gebruik het formaat 1234AA.';
        hasErrors = true;
    }
    if (!newForm.value.opleveraar) {
        formErrors.value.opleveraar = 'Opleveraar mag niet leeg zijn.';
        hasErrors = true;
    }
    if (!newForm.value.opleverdatum) {
        formErrors.value.opleverdatum = 'Opleverdatum mag niet leeg zijn.';
        hasErrors = true;
    }
    if (!newForm.value.email) {
        formErrors.value.email = 'Email mag niet leeg zijn.';
        hasErrors = true;
    }

    return !hasErrors;
};

const saveDraft = () => {
    if (!validateForm()) {
        return;
    }
    newForm.value.draft = true;
    alert('emiel moet dit saven in de backend with status Concept');
    console.log('Saving as draft with data:', newForm.value);
};

const saveForm = () => {
    if (!validateForm()) {
        return;
    }
    alert('emiel moet dit saven in de backend');
};

const toggleElementDisabled = (roomName, element) => {
    if (!newForm.value.disabledElements[roomName]) {
        newForm.value.disabledElements[roomName] = {};
    }
    newForm.value.disabledElements[roomName][element] = !newForm.value.disabledElements[roomName][element];
    newForm.value.status[element] = '';
};

const toggleRoomDisabled = (roomName) => {
    newForm.value.disabledRooms[roomName] = !newForm.value.disabledRooms[roomName];
    selectedRooms.value.find(room => room.name === roomName)?.elements.forEach(element => {
        newForm.value.status[element] = '';
    });
};

const allElementsChecked = computed(() => {
    return (roomName) => {
        const room = selectedRooms.value.find(room => room.name === roomName);
        if (!room || newForm.value.disabledRooms[roomName]) return false;
        
        return room.elements.every(element => {
            // Consider element as checked if it's either checked or disabled
            return newForm.value.status[element] === 'goed' || 
                   newForm.value.status[element] === 'gebrek' ||
                   newForm.value.disabledElements[roomName]?.[element];
        });
    };
});
</script>

<template>
    <Head title="Formulier naam" />

    <div class="flex justify-center">
        <div class="max-w-screen-2xl w-full h-auto md:h-24 lg:h-28 px-5 py-4 relative flex items-center md:justify-center">
            <img src="/public/img/logo.svg" alt="" class="absolute left-0 h-full w-auto p-4">
            <div class="ml-16 sm:ml-20 md:ml-0 flex flex-row gap-0 items-center">
                <Button type="href" :href="'/formulieren'" class="!shadow-none h-7 w-7 sm:h-8 sm:w-8 md:w-10 md:h-10 lg:w-14 lg:h-14 xl:w-16 xl:h-16">
                    <font-awesome-icon :icon="['far', 'chevron-left']" class="text-white h-6 w-6 sm:scale-105 md:scale-125 lg:scale-150 xl:scale-200" />
                </Button>
                <h1 class="text-base md:text-2xl lg:text-5xl font-bold text-white text-nowrap">Formulier OPL-2025-01-001</h1>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="max-w-screen-2xl relative w-full px-3 md:px-6 lg:px-8 xl:px-16">
            <form class="my-2 md:my-4 bg-white rounded-lg">
                <div class="p-2">
                    <h2 class="text-xl font-semibold">OPL-2025-01-001</h2>
                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                        <Input v-model="newForm.relatienummer" :inputId="'relatienummer'" :label="'Relatienummer'" :error="formErrors.relatienummer" class="w-full" />
                        <div class="flex flex-row flex-wrap">
                            <Input v-model="newForm.straatnaam" :inputId="'straatnaam'" :label="'Straatnaam'" :error="formErrors.straatnaam" class="w-full md:w-6/12 md:pr-2" />
                            <Input v-model="newForm.huisnummer" :inputId="'huisnummer'" :label="'Huisnummer'" :error="formErrors.huisnummer" class="mt-2 md:mt-0 w-2/3 pr-2 md:px-2 md:w-3/12" type="number"/>
                            <Input v-model="newForm.toevoeging" :inputId="'toevoeging'" :label="'Toevoeging'" class="mt-2 md:mt-0 w-1/3 pl-2 md:w-3/12" />
                        </div>
                        <div class="flex flex-row flex-wrap">
                            <Input v-model="newForm.postcode" :inputId="'postcode'" :label="'Postcode'" :error="formErrors.postcode" class="w-5/12 pr-2"  maxlength="7"/>
                            <div class="w-7/12 pl-2" style="position: relative;">
                                <Select v-model="newForm.woningtype" :options="roomType" valueName="name" valueId="id" :label="'Woningtype'" :error="formErrors.woningtype" class="w-full" />
                            </div>
                        </div>
                        <Input v-model="newForm.email" type="email" :inputId="'email'" :label="'Emailadres'" :error="formErrors.email" class="w-full" />
                        <Input v-model="newForm.opleveraar" :inputId="'opleveraar'" :label="'Opleveraar'" :error="formErrors.opleveraar" class="w-full" />
                        <DatePicker v-model="newForm.opleverdatum" :inputId="'opleverdatum'" :label="'Opleverdatum'" :error="formErrors.opleverdatum" class="w-full" />
                    </div>
                    
                    <div>
                        <div class="mt-4 w-full border-t pt-4 flex flex-col gap-4">
                            <h2 class="text-xl font-semibold">Sleutels</h2>
                            <div v-for="category in ['algemene toegang', 'voordeur', 'kamerdeur', 'balkon/terras', 'berging', 'postvak', 'parkeerbeugel', 'poort', 'meterkast', 'totaal']" :key="category" class="flex flex-col gap-2">
                                <h3 class="text-lg font-semibold">{{ category }}</h3>
                                <Input v-model="newForm.keys[category]" :inputId="`keys-${category}`" :label="`Aantal sleutels voor ${category}`" class="w-full" type="number" />
                                <Select v-model="newForm.keysReturnMethod[category]" :options="[{ id: 'onderling', name: 'Onderling' }, { id: 'via projectbeheerder', name: 'Via projectbeheerder' }, { id: 'via SJHT', name: 'Via SJHT' }]" valueName="name" valueId="id" :label="`Teruggave methode voor ${category}`" class="w-full" />
                            </div>
                        </div>

                        <div class="mt-4 w-full border-t pt-4 flex flex-col gap-4">
                            <h2 class="text-xl font-semibold">Meterstanden</h2>
                            <div v-for="meter in ['elektra 1', 'elektra 2', 'gas', 'water', 'warmte']" :key="meter" class="flex flex-col gap-2">
                                <Input v-model="newForm.meterReadings[meter]" :inputId="`meter-${meter}`" :label="`Meterstand voor ${meter}`" class="w-full" type="number" />
                            </div>
                        </div>

                        <div class="mt-4 w-full border-t pt-4 flex flex-col gap-4">
                            <h2 class="text-xl font-semibold">Passen</h2>
                            <div class="flex flex-col gap-2">
                                <Checkbox v-model="newForm.passes.milluepas" :inputId="'milluepas'" :label="'Milluepas aanwezig'" />
                                <Checkbox v-model="newForm.passes.slagboompas" :inputId="'slagboompas'" :label="'Slagboompas aanwezig'" />
                            </div>
                        </div>
                    </div>

                    <div v-if="newForm.woningtype" class="mt-4 w-full border-t pt-4 flex flex-col gap-4">
                        <div v-for="room in selectedRooms" :key="room.name" class="flex flex-col gap-1 w-full">
                            <div :class="[ newForm.disabledRooms[room.name] ? 'bg-gray-100 text-gray-300' : allElementsChecked(room.name) ? 'bg-ag text-white' : 'bg-gray-200 text-black', 'p-1 rounded-lg flex flex-row w-full items-center gap-2 relative' ]"> 
                                <div v-if="newForm.disabledRooms[room.name]" class="absolute left-0 right-0 h-px bg-black mr-2 ml-10 z-10"></div>
                                <Button class="!shadow-none h-8 w-8 group" @click="toggleRoomDisabled(room.name)">
                                    <font-awesome-icon :icon="['far', newForm.disabledRooms[room.name] ? 'plus' : 'minus']" :class="[ allElementsChecked(room.name) ? 'text-white group-hover:text-gray-200' : 'text-black group-hover:text-kr', 'h-6 w-6' ]" />
                                </Button>
                                <div class="w-6/12 lg:w-4/12 text-left">{{ room.name }}</div>
                                <div class="ml-auto lg:ml-0 max-w-14 w-full text-left">Goed</div>
                                <div class="max-w-14 w-full text-left">Gebrek</div>
                                <div class="hidden lg:flex flex-1 w-full text-left ml-2">Opmerking</div>
                            </div>
                            <div v-if="!newForm.disabledRooms[room.name]" v-for="element in room.elements" :key="element" class="px-1 flex flex-row flex-wrap lg:flex-nowrap w-full gap-2 relative">
                                <div v-if="newForm.disabledElements[room.name]?.[element]" class="absolute left-0 right-0 h-px bg-black mr-2 ml-10 z-10"></div>
                                <Button class="!shadow-none h-8 w-8 group lg:mb-auto mt-1" @click="toggleElementDisabled(room.name, element)">
                                    <font-awesome-icon :icon="['far', newForm.disabledElements[room.name]?.[element] ? 'plus' : 'minus']" class="text-black group-hover:text-kr h-6 w-6" />
                                </Button>
                                <p class="w-6/12 lg:w-4/12 font-semibold lg:mb-auto mt-2" :class="newForm.disabledElements[room.name]?.[element] ? 'text-gray-300' : ''">{{ element }}</p>
                                <div class="ml-auto lg:ml-0 lg:mb-auto mt-2 max-w-14 w-full flex justify-center items-center">
                                    <Checkbox :disabled="newForm.disabledElements[room.name]?.[element]" :model-value="newForm.status[element] === 'goed'" @update:model-value="(checked) => { console.log(`Goed checkbox for ${element}:`, { previous: newForm.status[element], new: checked ? 'goed' : '' }); newForm.status[element] = checked ? 'goed' : ''; console.log('Updated status:', newForm.status[element]); }" /> 
                                </div>
                                <div class="lg:mb-auto mt-2 max-w-14 w-full flex justify-center items-center">
                                    <Checkbox :disabled="newForm.disabledElements[room.name]?.[element]" :model-value="newForm.status[element] === 'gebrek'" @update:model-value="(checked) => { console.log(`Gebrek checkbox for ${element}:`, { previous: newForm.status[element], new: checked ? 'gebrek' : '' }); newForm.status[element] = checked ? 'gebrek' : ''; console.log('Updated status:', newForm.status[element]); }" />
                                </div>
                                <div :class="[ 'w-full flex-col gap-2 xl:flex-row', newForm.status[element] === 'gebrek' ? 'flex ' : 'hidden lg:flex' ]">
                                    <Input class="w-full" v-model="newForm.comments[element]" :inputId="`comment-${element}`" :label="''" :disabled="newForm.status[element] === 'goed' || newForm.disabledElements[room.name]?.[element]" />
                                    <div :class="[ 'w-full xl:max-w-xs flex-row flex-wrap gap-1', newForm.status[element] === 'gebrek' ? 'flex' : 'hidden lg:flex' ]" >
                                        <!-- hier komen alle bijlages -->
                                        <!-- <div :class="(newForm.status[element] === 'goed' || newForm.disabledElements[room.name]?.[element]) ? 'border-gray-200 bg-gray-100/10' : 'border-gray-400 bg-gray-400/10 text-gray-500'" class="rounded-full h-8 px-2.5 py-1.5 border-[1px] flex items-center">
                                            <Button class="!shadow-none" :disabled="newForm.status[element] === 'goed'">
                                                <p :class="(newForm.status[element] === 'goed' || newForm.disabledElements[room.name]?.[element]) ? 'text-gray-200' : 'text-gray-500'" class="text-nowrap">Bestand 123.jpg</p>
                                            </Button>
                                        </div> -->
                                        <div :class="(newForm.status[element] === 'goed' || newForm.disabledElements[room.name]?.[element]) ? 'border-gray-200 bg-gray-100/10' : 'border-gray-400 bg-gray-400/10 text-gray-500'" class="order-last rounded-full h-8 px-2.5 py-1.5 border-[1px] flex items-center">
                                            <Button class="!shadow-none" :disabled="newForm.status[element] === 'goed'">
                                                <font-awesome-icon :icon="['far', 'paperclip']" :class="(newForm.status[element] === 'goed' || newForm.disabledElements[room.name]?.[element]) ? 'text-gray-200' : 'text-gray-500'" class="h-4 w-4" />
                                                <p :class="(newForm.status[element] === 'goed' || newForm.disabledElements[room.name]?.[element]) ? 'text-gray-200' : 'text-gray-500'" class="text-nowrap">Bestand toevoegen</p>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4 w-full border-t pt-4 flex flex-col gap-4">
                        <Textarea v-model="newForm.paraaf" :inputId="'paraaf'" :label="'Paraaf'" :error="formErrors.paraaf" class="w-full" />
                    </div>
                </div>

                <div class="w-full flex justify-end flex-row items-center gap-2 mt-4 border-t p-2">
                    <Button @click="saveDraft" class="bg-white hover:bg-gray-100 h-9 w-auto">
                        <font-awesome-icon :icon="['far', 'pen-ruler']" class="text-black h-6 w-6" />
                        <p class="hidden md:block font-semibold text-black">Opslaan als concept</p>
                    </Button>
                    <Button @click="saveForm" class="bg-krz hover:bg-kr h-9 w-auto">
                        <font-awesome-icon :icon="['far', 'cloud-arrow-up']" class="text-white h-6 w-6" />
                        <p class="hidden md:block font-semibold text-white">Opslaan</p>
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>
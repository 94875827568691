<script setup>
import { ref, onMounted, watch } from 'vue';
import Button from '@/Components/Button.vue';

const props = defineProps({
    modelValue: {
        type: String,
        default: '',
    },
    label: {
        type: String,
        default: 'Handtekening',
    },
    error: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);

const canvas = ref(null);
const isDrawing = ref(false);
const canvasContext = ref(null);
const signatureExists = ref(false);

onMounted(() => {
    if (canvas.value) {
        canvasContext.value = canvas.value.getContext('2d');
        
        // Set canvas size
        canvas.value.width = canvas.value.offsetWidth;
        canvas.value.height = canvas.value.offsetHeight;
        
        // Initialize canvas
        initializeCanvas();
        
        // Load existing signature if provided
        if (props.modelValue && props.modelValue.startsWith('data:image')) {
            loadSignature(props.modelValue);
        } else if (props.modelValue) {
            loadExistingSignature('/storage/'+props.modelValue);
        }
    }
});

watch(() => props.modelValue, (newValue) => {
    if (newValue === '') {
        clearSignature();
    } else if (newValue && newValue.startsWith('data:image')) {
        loadSignature(newValue);
    } else if (newValue) {
        loadExistingSignature(newValue);
    }
});

const initializeCanvas = () => {
    if (!canvasContext.value) return;
    
    canvasContext.value.lineWidth = 2;
    canvasContext.value.lineCap = 'round';
    canvasContext.value.strokeStyle = '#000000';
    canvasContext.value.fillStyle = '#ffffff';
    canvasContext.value.fillRect(0, 0, canvas.value.width, canvas.value.height);
};

const startDrawing = (event) => {
    if (props.disabled) return;
    
    isDrawing.value = true;
    
    const { offsetX, offsetY } = getCoordinates(event);
    canvasContext.value.beginPath();
    canvasContext.value.moveTo(offsetX, offsetY);
    
    // Prevent scrolling on mobile devices
    if (event.cancelable) {
        event.preventDefault();
    }
    
    signatureExists.value = true;
};

const draw = (event) => {
    if (!isDrawing.value || props.disabled) return;
    
    const { offsetX, offsetY } = getCoordinates(event);
    canvasContext.value.lineTo(offsetX, offsetY);
    canvasContext.value.stroke();
    
    // Prevent scrolling on mobile devices
    if (event.cancelable) {
        event.preventDefault();
    }
};

const stopDrawing = () => {
    isDrawing.value = false;
    
    // Automatically save the signature when the user stops drawing
    if (signatureExists.value) {
        saveSignature();
    }
};

const getCoordinates = (event) => {
    let offsetX, offsetY;
    
    if (event.touches && event.touches.length > 0) {
        const rect = canvas.value.getBoundingClientRect();
        offsetX = event.touches[0].clientX - rect.left;
        offsetY = event.touches[0].clientY - rect.top;
    } else {
        offsetX = event.offsetX;
        offsetY = event.offsetY;
    }
    
    return { offsetX, offsetY };
};

const clearSignature = () => {
    if (canvasContext.value) {
        canvasContext.value.fillStyle = '#ffffff';
        canvasContext.value.fillRect(0, 0, canvas.value.width, canvas.value.height);
        signatureExists.value = false;
        emit('update:modelValue', '');
    }
};

const saveSignature = () => {
    if (!signatureExists.value) return;
    
    const dataURL = canvas.value.toDataURL('image/png');
    emit('update:modelValue', dataURL);
};

const loadSignature = (dataURL) => {
    const image = new Image();
    image.onload = () => {
        canvasContext.value.fillStyle = '#ffffff';
        canvasContext.value.fillRect(0, 0, canvas.value.width, canvas.value.height);
        canvasContext.value.drawImage(image, 0, 0);
        signatureExists.value = true;
    };
    image.src = dataURL;
};

const loadExistingSignature = (path) => {
    const image = new Image();
    image.onload = () => {
        canvasContext.value.fillStyle = '#ffffff';
        canvasContext.value.fillRect(0, 0, canvas.value.width, canvas.value.height);
        canvasContext.value.drawImage(image, 0, 0, canvas.value.width, canvas.value.height);
        signatureExists.value = true;
    };
    image.onerror = () => {
        console.error('Failed to load signature image');
        signatureExists.value = false;
    };
    image.src = path;
};
</script>

<template>
    <div class="w-full">
        <div class="mb-1 flex flex-row items-center justify-between">
            <label class="block text-sm font-semibold text-gray-900">
                {{ label }} <span v-if="required" class="text-red-600">*</span>
            </label>
        </div>
        
        <div class="relative">
            <canvas
                ref="canvas"
                class="w-full h-40 border border-gray-300 rounded-lg cursor-crosshair"
                @mousedown="startDrawing"
                @mousemove="draw"
                @mouseup="stopDrawing"
                @mouseleave="stopDrawing"
                @touchstart="startDrawing"
                @touchmove="draw"
                @touchend="stopDrawing"
                @touchcancel="stopDrawing"
            ></canvas>
            
            <div class="flex justify-end p-2">
                <Button 
                    type="button" 
                    @click="clearSignature" 
                    class="bg-white hover:bg-gray-100 shadow-md h-9 w-auto"
                    :disabled="disabled"
                >
                    <font-awesome-icon :icon="['far', 'trash']" class="text-black h-6 w-6" />
                    <p class="hidden md:block font-semibold text-black">Wissen</p>
                </Button>
            </div>
        </div>
        
        <div v-if="error" class="text-red-600 mt-1 text-sm">{{ error }}</div>
    </div>
</template>

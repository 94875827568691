<script setup>
import { Head, useForm, usePage } from '@inertiajs/vue3';
import { ref, watch, computed, onMounted } from 'vue';
import Select from '@/Components/Form/Select.vue';
import Checkbox from '@/Components/Form/Checkbox.vue';
import Button from '@/Components/Button.vue';
import Input from '@/Components/Form/Input.vue';
import DatePicker from '@/Components/Form/DatePicker.vue';
import GoogleAddress from '@/Components/Form/GoogleAddress.vue';
import SignatureCanvas from '@/Components/Form/SignatureCanvas.vue';
import Textarea from '@/Components/Form/Textarea.vue';
import Notifications from '@/Components/Notifications.vue';
import Image from '@/Components/Image.vue';
import moment from 'moment';

// Add reference to URL object for use in template
const URLObject = window.URL || window.webkitURL;

const props = defineProps({
    form: Object,
    preRoomsData: Array,
    preKeysData: Array,
    preMetricsData: Array,
    prePassesData: Array
})

const hasPreRoomsData = ref(props.preRoomsData.length > 0);

function getPreRoomData(roomName, elementName) {
    const room = props.preRoomsData.find(room => room.name === roomName);
    if (!room) return null;
    return room.elements.find(element => element.name === elementName);
}

// New function to check if an element had an issue in pre-inspection
function elementHadPreIssue(roomName, elementName) {
    const preElement = getPreRoomData(roomName, elementName);
    return preElement && preElement.issue === true;
}

const notificationList = ref(null);
 
const editForm = useForm({
    sjht_rel_id: props.form.sjht_rel_id,
    address: props.form.address,
    email: props.form.email,
    date: moment(props.form.date).format('DD-MM-YYYY'),
    conclusion: props.form.conclusion,
    rooms: props.form.rooms.map(room => {
        return {
            id: room.id,
            name: room.name,
            skipped: room.skipped,
            elements: room.elements.map(element => {
                return {
                    id: element.id,
                    name: element.name,
                    correct: element.correct,
                    skipped: element.skipped,
                    issue: element.issue,
                    description: element.description,
                    images: {
                        existing: element.images ? element.images.map(img => ({
                            url: img,
                            toDelete: false
                        })) : [],
                        new: []
                    }
                }
            })
        }
    }),
    pre_signature: props.form.pre_signature,
    signature: props.form.signature,
    concept: props.form.concept,
    type: props.form.type,
    keys: {
        'general_admission': props.form.keys.find(key => key.key === 'general_admission')?.amount ?? null,
        'front_door': props.form.keys.find(key => key.key === 'front_door')?.amount ?? null,
        'room_door': props.form.keys.find(key => key.key === 'room_door')?.amount ?? null,
        'balcony_terrace': props.form.keys.find(key => key.key === 'balcony_terrace')?.amount ?? null,
        'salvage': props.form.keys.find(key => key.key === 'salvage')?.amount ?? null,
        'mailbox': props.form.keys.find(key => key.key === 'mailbox')?.amount ?? null,
        'parking_bracket': props.form.keys.find(key => key.key === 'parking_bracket')?.amount ?? null,
        'gate': props.form.keys.find(key => key.key === 'gate')?.amount ?? null,
        'meter_cabinet': props.form.keys.find(key => key.key === 'meter_cabinet')?.amount ?? null
    },
    keysReturnMethod: props.form.keys.length > 0 ? props.form.keys[0].return_method : null,
    meterReadings: {
        'electric_one': props.form.metrics.find(meter => meter.metric === 'electric_one')?.amount ?? null,
        'electric_two': props.form.metrics.find(meter => meter.metric === 'electric_two')?.amount ?? null,
        'gas': props.form.metrics.find(meter => meter.metric === 'gas')?.amount ?? null,
        'water': props.form.metrics.find(meter => meter.metric === 'water')?.amount ?? null,
        'heat': props.form.metrics.find(meter => meter.metric === 'heat')?.amount ?? null
    },
    passes: {
        environment_pass: props.form.passes.find(pass => pass.pass === 'environment_pass')?.has_pass ?? false,
        barrier_pass: props.form.passes.find(pass => pass.pass === 'barrier_pass')?.has_pass ?? false
    }
})


// Add watchers to make correct and issue checkboxes mutually exclusive
editForm.rooms.forEach(room => {
    room.elements.forEach(element => {
        // Watch the correct property
        watch(() => element.correct, (newValue) => {
            if (newValue === true) {
                // If correct is checked, uncheck issue
                element.issue = false;
            }
        });

        // Watch the issue property
        watch(() => element.issue, (newValue) => {
            if (newValue === true) {
                // If issue is checked, uncheck correct
                element.correct = false;
            }
        });
    });
});

const roomType = [
    { id: 'self', name: 'Zelfstandig' },
    { id: 'group', name: 'Onzelfstandig' },
];

// Before form submission, prepare the images data
const prepareImagesForSubmission = () => {
    editForm.rooms.forEach(room => {
        room.elements.forEach(element => {
            // Convert the image structure for submission
            const imagesToKeep = element.images.existing
                .filter(img => !img.toDelete)
                .map(img => img.url);
            
            // Create FormData objects for new images if needed
            const newImages = element.images.new;

            const toDelete = element.images.existing
                .filter(img => img.toDelete)
                .map(img => img.url);
            
            // Replace the complex structure with the simplified one for submission
            element.images = {
                keep: imagesToKeep,
                new: newImages,
                deleted: toDelete
            };
        });
    });
};

const saveDraft = () => {
    //save as concept
    editForm.concept = true;

    // Prepare images for submission
    prepareImagesForSubmission();

    editForm.post('/formulieren/'+props.form.id, {
        preserveScroll: true,
        onSuccess: () => {
            //
        },
        onError: (errors) => {
            if (errors) {
                notificationList.value.add({
                    type: 'error',
                    title: 'Velden niet correct ingevuld',
                    text: 'Controleer de velden en probeer het opnieuw.',
                });
            }

            const pageForm = usePage().props.form;
            
            // Reset the image object structure for each element
            editForm.rooms.forEach((room, roomIndex) => {
                room.elements.forEach((element, elementIndex) => {
                    // Get images from the updated pageForm data
                    const updatedImages = pageForm.rooms[roomIndex]?.elements[elementIndex]?.images || [];
                    
                    // Reset the images structure to the expected format
                    element.images = {
                    existing: updatedImages.map(img => ({
                        url: img,
                        toDelete: false
                    })),
                    new: []
                    };
                });
            });
        }
    });
};

const saveForm = () => {
    //save form
    editForm.concept = false;
    
    // Prepare images for submission
    prepareImagesForSubmission();
    
    editForm.post('/formulieren/'+props.form.id, {
        preserveScroll: true,
        onSuccess: () => {
            //
        },
        onError: (errors) => {
            if (errors) {
                notificationList.value.add({
                    type: 'error',
                    title: 'Velden niet correct ingevuld',
                    text: 'Controleer de velden en probeer het opnieuw.',
                });
            }

            const pageForm = usePage().props.form;
            
            // Reset the image object structure for each element
            editForm.rooms.forEach((room, roomIndex) => {
                room.elements.forEach((element, elementIndex) => {
                    // Get images from the updated pageForm data
                    const updatedImages = pageForm.rooms[roomIndex]?.elements[elementIndex]?.images || [];
                    
                    // Reset the images structure to the expected format
                    element.images = {
                    existing: updatedImages.map(img => ({
                        url: img,
                        toDelete: false
                    })),
                    new: []
                    };
                });
            });
        }
    });
};

// Store the original state of elements when disabling them
const savedElementStates = ref({});

const toggleElementDisabled = (element) => {
    if (!element.skipped) {
        // Before disabling the element, save its current state
        savedElementStates.value[element.id] = {
            correct: element.correct,
            issue: element.issue,
            description: element.description,
            images: {
                existing: element.images.existing.map(img => ({
                    url: img.url,
                    toDelete: img.toDelete
                })),
                new: [...element.images.new] // Create a proper copy of the new images array
            }
        };
        
        // Reset element state when disabled
        element.correct = false;
        element.issue = false;
        element.description = '';
    } else {
        // When re-enabling the element, restore the saved state if it exists
        if (savedElementStates.value[element.id]) {
            const savedElement = savedElementStates.value[element.id];
            element.correct = savedElement.correct;
            element.issue = savedElement.issue;
            element.description = savedElement.description;
            
            // Restore images with a proper deep copy
            if (savedElement.images) {
                element.images = {
                    existing: savedElement.images.existing.map(img => ({
                        url: img.url,
                        toDelete: img.toDelete
                    })),
                    new: [...savedElement.images.new]
                };
            }
        }
    }
    
    // Toggle the skipped state
    element.skipped = !element.skipped;
};

const toggleRoomDisabled = (room) => {
    room.skipped = !room.skipped;
};

const allElementsChecked = (room) => {
    return room.elements.every(element => {
        // Consider element as checked if it's either checked or disabled
        // If element has an issue, require both description and images
        if (element.issue) {
            return element.description;
        }
        return element.skipped || element.correct;
    });
}

// Add this ref to store references to file inputs
const fileInputRefs = ref({});

// Add this function to trigger file input click
const triggerFileInput = (elementId) => {
    if (fileInputRefs.value[elementId]) {
        fileInputRefs.value[elementId].click();
    }
};

// Add function to handle existing image removal
const markExistingImageForDeletion = (element, index) => {
    element.images.existing[index].toDelete = true;
};

// Add function to remove new image
const removeNewImage = (element, index) => {
    element.images.new.splice(index, 1);
};

// Change this ref to store the selected image for the modal
const selectedImage = ref(null);
const imageComp = ref(null);

// Add function to show image in modal
const showImageInModal = (imageUrl) => {
    selectedImage.value = imageUrl;
    // Check if imageComp is available before accessing its property
    if (imageComp.value) {
        imageComp.value.showPopup = true;
    }
};

// Add this computed property to the script setup section
const totalKeys = computed(() => {
    let total = 0;
    for (const category in editForm.keys) {
        total += parseInt(editForm.keys[category] || 0);
    }
    return total;
});

// Add these refs for collapsible sections
const showKeysSection = ref(true);
const showMeterSection = ref(true);
const showPassesSection = ref(true);
const showRuimtesSection = ref(true);
const showConclusionSection = ref(true); // Add this new ref for conclusion section

// Add a computed property to check if all meter readings are filled
const allMeterReadingsFilled = computed(() => {
    //check if all meter readings are filled
    return Object.values(editForm.meterReadings).every(value => value !== null && value !== '');
});

// Modify the computed property to check if the return method is filled in
const allKeysFilled = computed(() => {    
    //check if all keys are filled except the totaal, and the return method is selected
    return Object.keys(editForm.keys).every(key => editForm.keys[key] !== null && editForm.keys[key] !== '') && editForm.keysReturnMethod !== null;
});

const allRoomsChecked = computed(() => {
    return editForm.rooms.every(room => {
        return room.skipped || allElementsChecked(room);
    });
});

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const allDataFilled = computed(() => {
    return editForm.sjht_rel_id != null && 
           editForm.sjht_rel_id != '' && 
           editForm.address != null && 
           editForm.address != '' && 
           editForm.email != null && 
           editForm.email != '' && 
           validateEmail(editForm.email) && 
           editForm.date != null && 
           editForm.date != '';
});

// Add this computed property to check if conclusion is filled
const conclusionFilled = computed(() => {
    return editForm.conclusion && editForm.conclusion.trim() !== '';
});

const allFieldsFilled = computed(() => {
    return allDataFilled.value && allKeysFilled.value && allMeterReadingsFilled.value && allRoomsChecked.value && editForm.signature;
});

function getKeyTranslation(key) {
    switch(key) {
        case 'general_admission':
            return 'Algemene toegang';
        case 'front_door':
            return 'Voordeur';
        case 'room_door':
            return 'Kamerdeur';
        case 'balcony_terrace':
            return 'Balkon/Terras';
        case 'salvage':
            return 'Berging';
        case 'mailbox':
            return 'Postvak';
        case 'parking_bracket':
            return 'Parkeerbeugel';
        case 'gate':
            return 'Poort';
        case 'meter_cabinet':
            return 'Meterkast';
        default:
            return key;
    }
}

function getMetricTranslation(metric) {
    switch(metric) {
        case 'electric_one':
            return 'Elektra 1';
        case 'electric_two':
            return 'Elektra 2';
        case 'gas':
            return 'Gas';
        case 'water':
            return 'Water';
        case 'heat':
            return 'Warmte';
        default:
            return metric;
    }
}
</script>

<template>
    <Notifications ref="notificationList" />
    <Head title="Edit" />

    <!-- Image component for viewing images in modal - remove v-if condition -->
    <Image :src="selectedImage || ''" class="hidden" ref="imageComp" />

    <div class="flex justify-center">
        <div class="max-w-screen-2xl w-full h-auto md:h-24 lg:h-28 px-5 py-4 relative flex items-center md:justify-center">
            <img src="/public/img/logo.svg" alt="" class="absolute left-0 h-full w-auto p-4">
            <div class="ml-16 sm:ml-20 md:ml-0 flex flex-row gap-0 items-center">
                <Button type="href" :href="'/formulieren'" class="!shadow-none h-7 w-7 sm:h-8 sm:w-8 md:w-10 md:h-10 lg:w-14 lg:h-14 xl:w-16 xl:h-16">
                    <font-awesome-icon :icon="['far', 'chevron-left']" class="text-white h-6 w-6 sm:scale-105 md:scale-125 lg:scale-150 xl:scale-200" />
                </Button>
                <h1 class="text-base md:text-2xl lg:text-5xl font-bold text-white text-nowrap">Formulier OPL-{{form.id}}</h1>
            </div>
        </div>
    </div>
    <div class="flex justify-center">
        <div class="max-w-screen-2xl relative w-full px-3 md:px-6 lg:px-8 xl:px-16">
            <form class="my-2 md:my-4">
                <div class="bg-white rounded-lg border border-gray-100 shadow-sm">
                    <div class="flex justify-between items-center p-3 cursor-pointer rounded-lg transition-all duration-500"
                         :class="allDataFilled ? 'bg-ag border-2 border-white text-white' : ''">
                        <div class="flex items-center gap-2">
                            <font-awesome-icon :icon="['far', 'address-card']" :class="allDataFilled ? 'text-white' : 'text-kr'" class="h-5 w-5 transition-all duration-500" />
                            <h2 class="text-xl font-semibold">Gegevens</h2>
                        </div>
                        <div class="flex items-center gap-2">
                            <span v-if="allDataFilled" class="text-xs font-medium bg-white text-ag rounded-full px-2 py-0.5">
                                Compleet
                            </span>
                        </div>
                    </div>

                    <div class="p-3 border-t">
                        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
                            <Input v-model="editForm.sjht_rel_id" :inputId="'relatienummer'" :label="'Relatienummer'" :error="editForm.errors.sjht_rel_id" class="w-full" />
                            <GoogleAddress v-model="editForm.address" :inputId="'address'" :label="'Adres'" :error="editForm.errors.address" class="w-full" />
                            <Select v-model="editForm.type" readonly :options="roomType" valueName="name" valueId="id" :label="'Woningtype'" :error="editForm.errors.address" class="w-full" />

                            <Input v-model="editForm.email" type="email" :inputId="'email'" :label="'E-mailadres'" :error="editForm.errors.email" class="w-full" />
                            <!-- <Input v-model="newForm.opleveraar" :inputId="'opleveraar'" :label="'Opleveraar'" :error="formErrors.opleveraar" class="w-full" /> -->
                            <DatePicker v-model="editForm.date" :inputId="'opleverdatum'" :label="'Opleverdatum'" :error="editForm.errors.date" class="w-full" />
                        </div>
                    </div>
                </div>
                
                <div class="mt-2 sm:mt-4 w-full flex flex-col gap-2 sm:gap-4">
                    <!-- Keys Section -->
                    <div class="bg-white rounded-lg border border-gray-100 shadow-sm duration-300" v-auto-animate>
                        <div @click="showKeysSection = !showKeysSection" 
                             class="flex justify-between items-center p-3 cursor-pointer rounded-lg transition-all duration-500"
                             :class="allKeysFilled ? 'bg-ag border-2 border-white text-white' : 'hover:bg-gray-50'">
                            <div class="flex items-center gap-2">
                                <font-awesome-icon :icon="['far', 'key']" :class="allKeysFilled ? 'text-white' : 'text-kr'" class="h-5 w-5" />
                                <h2 class="text-xl font-semibold">Sleutels</h2>
                            </div>
                            <div class="flex items-center gap-2">
                                <span v-if="allKeysFilled" class="text-xs font-medium bg-white text-ag rounded-full px-2 py-0.5">
                                    Compleet
                                </span>
                                <font-awesome-icon :icon="['far', 'chevron-down']" :class="[allKeysFilled ? 'text-white' : 'text-gray-500', showKeysSection ? '-rotate-180' : '', 'transition-transform duration-300']" class="h-4 w-4" />
                            </div>
                        </div>
                        
                        <div v-if="showKeysSection" class="p-3 border-t transition-all duration-500" :class="allKeysFilled ? 'border-none' : 'border-t'">
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4">
                                <div v-for="category in ['general_admission', 'front_door', 'room_door', 'balcony_terrace', 'salvage', 'mailbox', 'parking_bracket', 'gate', 'meter_cabinet']" 
                                     :key="category" 
                                     class="flex flex-row justify-between gap-2 p-2 rounded-lg hover:bg-gray-50 transition-all duration-500">
                                    <h3 class="text-base font-medium flex items-center gap-2">
                                        <span class="w-6 h-6 rounded-full flex items-center justify-center transition-all duration-500" 
                                              :class="editForm.keys[category] != null && editForm.keys[category] !== '' ? 'bg-ag' : 'bg-gray-100'">
                                            <font-awesome-icon :icon="['far', 'key']" 
                                                          :class="editForm.keys[category] != null && editForm.keys[category] !== '' ? 'text-white' : 'text-gray-500'" 
                                                          class="h-3 w-3 transition-all duration-500" />
                                        </span>
                                        {{ getKeyTranslation(category) }}
                                    </h3>
                                    <div :class="props.preKeysData.length > 0 ? 'w-28' : 'w-18'" class="flex items-center gap-2">
                                        <label class="bg-kr p-1 rounded-md text-white w-12 text-center" v-if="props.preKeysData.length > 0">{{ props.preKeysData.find(key => key.key == category).amount }}</label>
                                        <Input v-model="editForm.keys[category]" 
                                               :inputId="`keys-${category}`" 
                                               class="w-full" 
                                               type="number"
                                               :min="0" />
                                    </div>
                                </div>
                                
                                <!-- Total Keys Section -->
                                <div class="md:col-span-2 lg:col-span-3 p-3 rounded-lg border bg-gray-50 border-gray-100 mt-2 transition-all duration-500">
                                    <div class="flex flex-col sm:flex-row gap-4 items-center">
                                        <div class="w-full">
                                            <h3 class="text-lg font-semibold flex items-center gap-2">
                                                <font-awesome-icon :icon="['far', 'calculator']" class="text-kr h-4 w-4" />
                                                Totaal
                                            </h3>
                                            <Input v-model="totalKeys" 
                                                   :inputId="'keys-totaal'" 
                                                   class="w-full font-bold" 
                                                   type="text" 
                                                   :min="0" 
                                                   readOnly 
                                                   /> 
                                        </div>
                                        <div class="w-full">
                                            <h3 class="text-lg font-semibold text-nowrap">
                                                Teruggave methode
                                            </h3>
                                            <Select v-model="editForm.keysReturnMethod"
                                                    :options="[
                                                        { id: 'onderling', name: 'Onderling' }, 
                                                        { id: 'via projectbeheerder', name: 'Via projectbeheerder' }, 
                                                        { id: 'via SJHT', name: 'Via SJHT' }
                                                    ]"
                                                    valueName="name"
                                                    placeholder="Selecteer teruggave methode"
                                                    valueId="id"
                                                    class="w-full" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Meter Readings Section -->
                    <div class="bg-white rounded-lg border border-gray-100 shadow-sm transition-all duration-500" v-auto-animate>
                        <div @click="showMeterSection = !showMeterSection" class="flex justify-between items-center px-3 cursor-pointer rounded-lg transition-all duration-500" :class="allMeterReadingsFilled ? 'bg-ag border-2 py-2.5 border-white text-white' : 'py-3 hover:bg-gray-50'">
                            <div class="flex items-center gap-2">
                                <font-awesome-icon :icon="['far', 'gauge']" :class="allMeterReadingsFilled ? 'text-white' : 'text-kr'" class="h-5 w-5" />
                                <h2 class="text-xl font-semibold">Meterstanden</h2>
                            </div>
                            <div class="flex items-center gap-2">
                                <span v-if="allMeterReadingsFilled" class="text-xs font-medium bg-white text-ag rounded-full px-2 py-0.5">
                                    Compleet
                                </span>
                                <font-awesome-icon :icon="['far', 'chevron-down']" :class="[allMeterReadingsFilled ? 'text-white' : 'text-gray-500', showMeterSection ? '-rotate-180 ' : '', 'transition-transform duration-300']" class="h-4 w-4" />
                            </div>
                        </div>
                        
                        <div v-if="showMeterSection" class="p-3 border-t transition-all duration-500" :class="allMeterReadingsFilled ? 'border-none' : 'border-t'">
                            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                <div v-for="meter in ['electric_one', 'electric_two', 'gas', 'water', 'heat']" 
                                     :key="meter" 
                                     class="flex flex-col gap-2 p-2 rounded-lg hover:bg-gray-50 transition-all duration-500">
                                    <div class="flex justify-between">
                                        <h3 class="text-base font-medium flex items-center gap-2">
                                            <span class="w-6 h-6 rounded-full flex items-center justify-center transition-all duration-500" 
                                                  :class="editForm.meterReadings[meter] != null && editForm.meterReadings[meter] !== '' ? 'bg-ag' : 'bg-gray-100'">
                                                <font-awesome-icon :icon="meter.includes('electric') ? ['far', 'bolt'] : 
                                                                   meter.includes('gas') ? ['far', 'fire'] : 
                                                                   meter.includes('water') ? ['far', 'droplet'] : 
                                                                   ['far', 'temperature-arrow-up']"  
                                                         :class="editForm.meterReadings[meter] != null && editForm.meterReadings[meter] !== '' ? 'text-white' : 'text-gray-500'"
                                                         class="h-3 w-3 transition-all duration-500" />
                                            </span>
                                            {{ getMetricTranslation(meter) }}
                                        </h3>
                                        <div v-if="props.preMetricsData.length > 0">
                                            <label class="bg-kr p-1 rounded-md text-white w-12 text-center">{{ props.preMetricsData.find(metric => metric.metric == meter).amount }}</label>
                                        </div>
                                    </div>
                                    <Input v-model="editForm.meterReadings[meter]" 
                                           :inputId="`meter-${meter}`" 
                                           :label="`Meterstand`" 
                                           :labelClass="allMeterReadingsFilled ? 'text-white/80' : ''"
                                           class="w-full" 
                                           type="number"
                                           step="0.01"
                                           placeholder="0.00" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Passes Section -->
                    <div class="bg-white rounded-lg border border-gray-100 shadow-sm transition-all duration-500" v-auto-animate>
                        <div @click="showPassesSection = !showPassesSection" class="flex justify-between items-center p-3 cursor-pointer rounded-lg transition-all duration-500" :class="editForm.passes.environment_pass && editForm.passes.barrier_pass ? 'bg-ag border-2 border-white text-white' : 'hover:bg-gray-50'">
                            <div class="flex items-center gap-2">
                                <font-awesome-icon :icon="['far', 'cards-blank']" :class="[editForm.passes.environment_pass && editForm.passes.barrier_pass ? 'text-white' : 'text-kr', 'h-5 w-5']" />
                                <h2 class="text-xl font-semibold">Passen</h2>
                            </div>
                            
                            <div class="flex items-center gap-2">
                                <span v-if="editForm.passes.environment_pass && editForm.passes.barrier_pass" class="text-xs font-medium bg-white text-ag rounded-full px-2 py-0.5">
                                    Compleet
                                </span>
                                <font-awesome-icon :icon="['far', 'chevron-down']" :class="[editForm.passes.environment_pass && editForm.passes.barrier_pass ? 'text-white' : 'text-gray-500', showPassesSection ? '-rotate-180 ' : '', 'transition-transform duration-300']" class="h-4 w-4" />
                            </div>
                        </div>
                        
                        <div v-if="showPassesSection" class="p-3 border-t transition-all duration-500">
                            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div :class="[
                                    prePassesData.length > 0 
                                        ? (prePassesData.find(pass => pass.pass == 'environment_pass').has_pass 
                                            ? 'border-2 border-ag' 
                                            : 'border-2 border-kr') 
                                        : ''
                                    ]" 
                                    class="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 hover:cursor-pointer transition-all duration-500" 
                                    @click="editForm.passes.environment_pass = !editForm.passes.environment_pass">
                                    <span class="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center transition-all duration-500">
                                        <font-awesome-icon :icon="['far', 'leaf']" class="text-green-600 h-5 w-5 transition-all duration-500" />
                                    </span>
                                    <div class="flex-1">
                                        <label class="text-base font-medium">Milluepas</label>
                                        <p class="text-sm text-gray-500">Milluepas voor afvalverwerking</p>
                                    </div>
                                    <Checkbox v-model="editForm.passes.environment_pass" :inputId="'milluepas'" />
                                </div>
                                
                                <div :class="[
                                    prePassesData.length > 0 
                                        ? (prePassesData.find(pass => pass.pass == 'barrier_pass').has_pass 
                                            ? 'border-2 border-ag' 
                                            : 'border-2 border-kr') 
                                        : ''
                                    ]" class="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 hover:cursor-pointer transition-all duration-500" @click="editForm.passes.barrier_pass = !editForm.passes.barrier_pass">
                                    <span class="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center transition-all duration-500">
                                        <font-awesome-icon :icon="['far', 'car']" class="text-blue-600 h-5 w-5 transition-all duration-500" />
                                    </span>
                                    <div class="flex-1">
                                        <label class="text-base font-medium">Slagboompas / Afstandsbediening</label>
                                        <p class="text-sm text-gray-500">Pas voor toegang tot parkeerplaats</p>
                                    </div>
                                    <Checkbox v-model="editForm.passes.barrier_pass" :inputId="'slagboompas'" />
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Ruimtes Section -->
                    <div class="bg-white rounded-lg border border-gray-100 shadow-sm transition-all duration-500" v-auto-animate>
                        <div @click="showRuimtesSection = !showRuimtesSection" class="flex justify-between items-center p-3 cursor-pointer rounded-lg transition-all duration-500" :class="allRoomsChecked ? 'bg-ag border-2 border-white text-white' : 'hover:bg-gray-50'">
                            <div class="flex items-center gap-2">
                                <font-awesome-icon :icon="['far', 'door-closed']" :class="[allRoomsChecked ? 'text-white' : 'text-kr', '']" class="h-5 w-5 transition-all duration-300" />
                                <h2 class="text-xl font-semibold">Ruimtes</h2>
                            </div>
                            <div class="flex items-center gap-2">
                                <span v-if="allRoomsChecked" class="text-xs font-medium bg-white text-ag rounded-full px-2 py-0.5">
                                    Compleet
                                </span>
                                <font-awesome-icon :icon="['far', 'chevron-down']" :class="[allRoomsChecked ? 'text-white' : 'text-gray-500', showRuimtesSection ? '-rotate-180 ' : '', 'transition-transform duration-300']" class="h-4 w-4" />
                            </div>
                        </div>

                        <div v-if="showRuimtesSection" class="p-3 border-t transition-all duration-500">
                            <div class="flex flex-col gap-2 w-full">
                                <div v-for="room in editForm.rooms" :key="room.name" class="flex flex-col gap-1 w-full" v-auto-animate>
                                    <div v-auto-animate :class="[ room.skipped ? 'bg-gray-100 text-gray-300' : allElementsChecked(room) ? 'bg-ag text-white' : 'bg-gray-200 text-black', 'p-1 rounded-lg flex flex-row w-full items-center gap-2 relative transition-all duration-500' ]"> 
                                        <div v-if="room.skipped" class="absolute left-0 right-0 h-px bg-black mr-2 ml-10 z-10"></div>
                                        <Button class="!shadow-none h-8 w-8 group transition-all duration-500" @click="toggleRoomDisabled(room)">
                                            <font-awesome-icon :icon="['far', room.skipped ? 'plus' : 'minus']" :class="[ allElementsChecked(room) ? 'text-white group-hover:text-gray-200' : 'text-black group-hover:text-kr', 'h-6 w-6 transition-all duration-500' ]" />
                                        </Button>
                                        <div class="w-full lg:w-2/12 text-left">{{ room.name }}</div>
                                        <div class="ml-auto lg:ml-0 max-w-14 w-full text-left">Goed</div>
                                        <div class="max-w-14 w-full text-left">Gebrek</div>
                                        <div class="hidden lg:flex flex-1 w-full text-left ml-2">Opmerking</div>
                                        <div class="hidden lg:w-4/12 text-left rounded-r-lg">Bijlage</div>
                                    </div>
                                    <div v-auto-animate v-if="!room.skipped" v-for="element in room.elements" :key="element" class="text-black rounded-xl p-1 flex flex-col w-full gap-2 transition-all duration-500">
                                        <!-- Show pre-inspection data if the element had an issue -->
                                        <div v-if="hasPreRoomsData && elementHadPreIssue(room.name, element.name)" v-auto-animate class="bg-kr/10 border-l-4 border-kr min-h-11 flex flex-row flex-wrap w-full items-center gap-2 relative p-1 rounded-r-xl transition-all duration-500">
                                            <p class="w-fit lg:w-2/12 ml-2 mr-6 font-semibold text-kr">
                                                Vooroplevering: {{ element.name }}
                                            </p>
                                            <div class="ml-auto lg:ml-0 max-w-14 w-full flex justify-center items-center">
                                                <Checkbox disabled :model-value="false" />
                                            </div>
                                            <div class="max-w-14 w-full flex justify-center items-center">
                                                <Checkbox disabled :model-value="true" />
                                            </div>
                                            <div class="flex lg:flex-1 w-full flex-row flex-wrap justify-between items-center">
                                                <p class="w-auto text-sm text-kr font-medium px-2">
                                                    {{ getPreRoomData(room.name, element.name)?.description || 'Geen omschrijving' }}
                                                </p>
                                                <div v-if="getPreRoomData(room.name, element.name)?.images && getPreRoomData(room.name, element.name).images.length > 0" >
                                                    <div class="flex flex-row lg:justify-end flex-wrap gap-2">
                                                        <!-- Pre-inspection images -->
                                                        <div v-for="(image, index) in getPreRoomData(room.name, element.name).images" :key="`pre-${index}`" 
                                                            class="flex items-center gap-2 p-2 rounded-lg border border-kr/20 bg-kr/5">
                                                            <div class="flex items-center gap-1 flex-1 overflow-hidden">
                                                                <font-awesome-icon :icon="['far', 'image']" class="text-kr h-4 w-4" />
                                                                <span 
                                                                    class="truncate text-sm cursor-pointer hover:text-kr text-kr/80 max-w-52" 
                                                                    @click="showImageInModal('/storage/'+image)"
                                                                >
                                                                    {{ image.split('/').pop() }}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Current inspection row -->
                                        <div class="flex flex-row flex-wrap w-full items-center gap-2 relative transition-all duration-500" v-auto-animate>
                                            <div v-if="element.skipped" class="absolute left-0 right-0 h-px bg-black mr-2 ml-10 z-10 transition-all duration-500"></div>
                                            <Button class="!shadow-none h-8 w-8 group transition-all duration-500" @click="toggleElementDisabled(element)">
                                                <font-awesome-icon :icon="['far', element.skipped ? 'plus' : 'minus']" class="text-black group-hover:text-kr h-6 w-6 transition-all duration-500" />
                                            </Button>
                                            <p :class="element.skipped ? 'text-gray-300' : ''" class="w-fit lg:w-2/12 font-semibold transition-all duration-500">{{ element.name }}</p>
                                            <div class="ml-auto lg:ml-0 max-w-14 w-full flex justify-center items-center">
                                                <Checkbox :disabled="element.skipped" v-model="element.correct" /> 
                                            </div>
                                            <div class="max-w-14 w-full flex justify-center items-center">
                                                <Checkbox :disabled="element.skipped" v-model="element.issue" />
                                            </div>
                                            <div class="flex lg:flex-1 w-full" v-if="element.issue">
                                                <p class="w-full">
                                                    <Input v-model="element.description" :disabled="element.skipped" :inputId="'issue'" :error="editForm.errors.issue" class="w-full" />
                                                </p>
                                            </div>
                                            <div class="w-full justify-end flex" v-if="element.issue" v-auto-animate>
                                                <div v-auto-animate class="flex flex-row lg:justify-end flex-wrap gap-2">
                                                    <!-- Existing images section -->
                                                    <div v-for="(image, index) in element.images.existing" :key="`existing-${index}`" 
                                                        class="flex items-center gap-2 p-2 rounded-lg border border-gray-200 bg-gray-50 transition-all duration-500">
                                                        <div v-if="!image.toDelete">
                                                            <div class="flex items-center gap-1 flex-1 overflow-hidden">
                                                                <font-awesome-icon :icon="['far', 'image']" class="text-gray-500 h-4 w-4" />
                                                                <span 
                                                                    class="truncate text-sm cursor-pointer hover:text-blue-500 max-w-52" 
                                                                    @click="showImageInModal('/storage/'+image.url)"
                                                                >
                                                                    {{ image.url.split('/').pop() }}
                                                                </span>
                                                            </div>
                                                            <Button class="!shadow-none h-6 w-6 hover:bg-gray-200 rounded-full transition-all duration-500" @click="markExistingImageForDeletion(element, index)">
                                                                <font-awesome-icon :icon="['far', 'xmark']" class="text-gray-500 h-4 w-4 transition-all duration-500" />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    
                                                    <!-- New images section -->
                                                    <div v-for="(image, index) in element.images.new" :key="`new-${index}`" 
                                                        class="rounded-full h-8 px-2.5 py-1.5 border-[1px] flex items-center border-gray-200 bg-gray-50 transition-all duration-500">
                                                        <div class="flex items-center gap-1 flex-1 overflow-hidden">
                                                            <font-awesome-icon :icon="['far', 'image']" class="text-gray-500 h-4 w-4" />
                                                            <span 
                                                                class="truncate text-sm cursor-pointer hover:text-blue-500 max-w-52" 
                                                                @click="showImageInModal(URLObject.createObjectURL(image))"
                                                            >
                                                                {{ image.name }}
                                                            </span>
                                                        </div>
                                                        <Button class="!shadow-none h-6 w-6 hover:bg-gray-200 rounded-full transition-all duration-500" @click="removeNewImage(element, index)">
                                                            <font-awesome-icon :icon="['far', 'xmark']" class="text-gray-500 h-4 w-4 transition-all duration-500" />
                                                        </Button>
                                                    </div>
                                                    
                                                    <!-- File upload button -->
                                                    <div class="w-full md:w-auto flex justify-end order-last">
                                                        <div :class="(element.correct || element.skipped) ? 'border-gray-200 bg-gray-100/10' : 'border-gray-400 bg-gray-400/10 text-gray-500'" class="rounded-full h-8 px-2.5 py-1.5 border-[1px] flex items-center transition-all duration-500">
                                                            <input type="file" :ref="el => { fileInputRefs[`file-${room.name}-${element.name}`] = el }" class="hidden" 
                                                                @change="(e) => { 
                                                                    if (e.target.files) {
                                                                        for (let i = 0; i < e.target.files.length; i++) {
                                                                            element.images.new.push(e.target.files[i]);
                                                                        }
                                                                        e.target.value = ''; // Reset file input
                                                                    }
                                                                }" multiple accept="image/png, image/jpeg, image/webp, image/svg+xml" :disabled="element.correct || element.skipped" />
                                                            <Button class="!shadow-none transition-all duration-500" :disabled="element.correct || element.skipped" @click="triggerFileInput(`file-${room.name}-${element.name}`)">
                                                                <font-awesome-icon :icon="['far', 'paperclip']" :class="(element.correct || element.skipped) ? 'text-gray-200' : 'text-gray-500'" class="h-4 w-4 transition-all duration-500" />
                                                                <p :class="(element.correct || element.skipped) ? 'text-gray-200' : 'text-gray-500'" class="text-nowrap font-medium text-xs transition-all duration-500">Bestand toevoegen</p>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Conclusion Section -->
                    <div class="bg-white rounded-lg border border-gray-100 shadow-sm transition-all duration-500" v-if="form.status != 'pre'" v-auto-animate>
                        <div @click="showConclusionSection = !showConclusionSection" 
                             class="flex justify-between items-center p-3 cursor-pointer rounded-lg transition-all duration-500" 
                             :class="conclusionFilled ? 'bg-ag border-2 border-white text-white' : 'hover:bg-gray-50'">
                            <div class="flex items-center gap-2">
                                <font-awesome-icon :icon="['far', 'clipboard-list-check']" :class="conclusionFilled ? 'text-white' : 'text-kr'" class="h-5 w-5 transition-all duration-500" />
                                <h2 class="text-xl font-semibold">Conclusie</h2>
                            </div>
                            <div class="flex items-center gap-2">
                                <span v-if="conclusionFilled" class="text-xs font-medium bg-white text-ag rounded-full px-2 py-0.5">
                                    Compleet
                                </span>
                                <font-awesome-icon :icon="['far', 'chevron-down']" 
                                                 :class="[conclusionFilled ? 'text-white' : 'text-gray-500', 
                                                 showConclusionSection ? '-rotate-180' : '', 
                                                 'transition-transform duration-300']" 
                                                 class="h-4 w-4" />
                            </div>
                        </div>

                        <div v-if="showConclusionSection" class="p-3 border-t transition-all duration-500" :class="conclusionFilled ? 'border-none' : 'border-t'">
                            <Textarea v-model="editForm.conclusion" :maxLength="500" :label="'Omschrijving'" :error="editForm.errors.conclusion" />
                        </div>
                    </div>
                    
                    <!-- Signature Section -->
                    <div class="bg-white rounded-lg border border-gray-100 shadow-sm transition-all duration-500">
                        <div class="flex justify-between items-center p-3 cursor-pointer rounded-lg transition-all duration-500" :class="editForm.signature ? 'bg-ag border-2 border-white text-white' : 'hover:bg-gray-50'">
                            <div class="flex items-center gap-2">
                                <font-awesome-icon :icon="['far', 'signature']" :class="[editForm.signature ? 'text-white' : 'text-kr']" class="h-5 w-5 transition-all duration-500" />
                                <h2 class="text-xl font-semibold">Handtekening</h2>
                            </div>
                            <div class="flex items-center gap-2">
                                <span v-if="editForm.signature" class="text-xs font-medium bg-white text-ag rounded-full px-2 py-0.5">
                                    Compleet
                                </span>
                            </div>
                        </div>

                        <div class="p-3 border-t transition-all duration-500">
                            <SignatureCanvas v-model="editForm.signature" :error="editForm.errors.signature" />
                        </div>
                    </div>
                </div>

                <div class="w-full flex justify-end flex-row items-center gap-2 mt-2 sm:mt-4">
                    <Button @click="saveDraft" class="bg-white hover:bg-gray-100 h-9 w-auto transition-all duration-500">
                        <font-awesome-icon :icon="['far', 'pen-ruler']" class="text-black h-6 w-6 transition-all duration-500" />
                        <p class="font-semibold text-black transition-all duration-500">Opslaan als concept</p>
                    </Button>
                    <Button @click="saveForm" class="bg-krz hover:bg-kr h-9 w-auto transition-all duration-500" v-if="allFieldsFilled">
                        <font-awesome-icon :icon="['far', 'cloud-arrow-up']" class="text-white h-6 w-6 transition-all duration-500" />
                        <p class="font-semibold text-white transition-all duration-500">Opslaan</p>
                    </Button>
                </div>
            </form>
        </div>
    </div>
</template>
<script setup>
import Input from '@/Components/Form/Input.vue';
import { useForm, Head } from '@inertiajs/vue3';

const form = useForm({
    email: '',
    password: '',
});

const submit = () => {
    form.post('/login', {
        onSuccess: () => {
            form.reset();
        },
    })
};
</script>
<template>
    <Head title="Login | SJHT portaal" />
    
    <section class="relative bg-[url('/img/sjht_b_bg_hero.webp')] bg-left bg-cover min-h-screen flex flex-col">
        <div class="hidden md:flex absolute h-full items-end bottom-0 left-0 z-10">
            <img :src="'/img/sjht_b_big_hero_17.webp'" alt="" class="max-h-full">
        </div>
        <form @submit.prevent="submit" method="POST" class="relative z-20 flex flex-col flex-1 h-full">
            <img :src="'/img/logo.svg'" alt="" class="max-h-16 absolute left-1/2 transform -translate-x-1/2 sm:left-8 sm:translate-x-0 top-8">
            <img :src="'/img/sjht_b_big_hero_17.webp'" alt="" class="mt-28 sm:mt-8 md:hidden">
            <div class="flex flex-col flex-1 w-full bg-white rounded-t-4xl md:bg-transparent md:rounded-t-none p-8 gap-12 items-center md:items-end md:justify-center max-w-7xl mx-auto mt-auto md:mt-0">
                <div class="w-full md:max-w-sm lg:max-w-lg flex flex-col item-center gap-8">
                    <h1 class="lowercase mx-auto text-shadow-lg md:text-shadow font-ROC-Grotesk font-medium md:font-extrabold leading-none text-2xl md:text-5xl lg:text-7xl xl:text-8xl tracking-tight text-black md:text-white text-center">
                        Eerst even inloggen.</h1>
                    <div class="flex w-full flex-col gap-2">
                        <Input v-model="form.email" type="email" inputId="email" placeholder="E-mailadres" required :error="form.errors.email" class="w-full" rounded="full" />

                        <Input v-model="form.password" type="password" inputId="password" placeholder="Wachtwoord" required :error="form.errors.password" class="w-full" rounded="full" />
                    </div> 
                    <div class="flex flex-row flex-wrap justify-center w-48 gap-2 mx-auto">
                        <button type="submit" class="w-full leading-loose text-center cursor-pointer bg-ag hover:bg-ag-hover rounded-full text-white text-xl font-medium" :disabled="form.processing">
                            Inloggen
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </section>
</template>
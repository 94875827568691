<script setup>
import { Head, Link, useForm } from '@inertiajs/vue3';
import { ref, computed } from 'vue';
import Select from '@/Components/Form/Select.vue';
import Button from '@/Components/Button.vue';
import DateRange from '@/Components/Form/DateRange.vue';
import GoogleAddress from '@/Components/Form/GoogleAddress.vue';
import Input from '@/Components/Form/Input.vue';
import moment from 'moment';
import {
    Combobox,
    ComboboxInput,
    ComboboxOptions,
    ComboboxOption,
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import DatePicker from '@/Components/Form/DatePicker.vue';
import DeleteDialog from '@/Components/DeleteDialog.vue';
import { hasPermission } from '@/Composables/roles.js';

const deleteDialog = ref(null);

const props = defineProps({
    forms: Array,
    users: Array,
    currentUserId: Number,
});

const items = ref(props.forms);

const activeTab = ref(0);

const setActiveTab = (index) => {
    activeTab.value = index;
};

const dateOptions = [
    { id: 'last7', name: 'Laatste 7 dagen' },
    { id: 'lastMonth', name: 'Afgelopen maand' },
    { id: 'last3Months', name: 'Laatste 3 maanden' },
    { id: 'last6Months', name: 'Laatste 6 maanden' },
    { id: 'thisYear', name: 'Huidig jaar' },
    { id: 'all', name: 'Alle items tonen' }
];

const startStatuses = [
    { id: 'pre', name: 'Vooroplevering' },
    { id: 'end', name: 'Eindoplevering' },
];

// Update dateFilter initialization to default to last 7 days
const dateFilter = ref({
    preset: 'last7',
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
});

const filteredByDate = computed(() => {
    return items.value.filter(item => {
        const itemDate = moment(item.updated_at);

        if (!dateFilter.value.preset || dateFilter.value.preset === 'all') {
            return true;
        }

        // Handle custom date range
        if (dateFilter.value.preset === 'custom' && dateFilter.value.startDate && dateFilter.value.endDate) {
            const start = moment(dateFilter.value.startDate).startOf('day');
            const end = moment(dateFilter.value.endDate).endOf('day');
            return moment(itemDate).isBetween(start, end, 'day', '[]');
        }

        // Handle preset ranges
        switch (dateFilter.value.preset) {
            case 'last7':
                return moment(itemDate).isAfter(moment().subtract(7, 'days'));
            case 'lastMonth':
                return moment(itemDate).isAfter(moment().subtract(1, 'month'));
            case 'last3Months':
                return moment(itemDate).isAfter(moment().subtract(3, 'months'));
            case 'last6Months':
                return moment(itemDate).isAfter(moment().subtract(6, 'months'));
            case 'thisYear':
                return moment(itemDate).isSame(moment(), 'year');
            default:
                return true;
        }
    });
});

const filteredStatus = computed(() => {
    const statusFiltered = items.value.filter(item => {
        switch (activeTab.value) {
            case 1:
                return item.status === 'pre';
            case 2:
                return item.status === 'end';
            case 3:
                return item.status === 'completed';
            case 4:
                return item.status === 'sent';
            case 5:
                return item.status === 'signed';
            case 6:
                return item.concept;
            default:
                return true;
        }
    });
    return filteredByDate.value.filter(item => statusFiltered.includes(item));
});

const open = ref(false)
const query = ref('')

const filteredItems = computed(() => {
    if (query.value === '') {
        return [];
    }
    
    const searchTerms = query.value.toLowerCase().trim().split(/\s+/);
    
    return items.value
        .filter(item => {
            // Check if all search terms match at least one field
            return searchTerms.every(term => {
                // Check ID
                if (('OPL-' + item.id).toLowerCase().includes(term)) {
                    return true;
                }
                
                // Check Address components
                const fullAddress = item.address.route + ' ' + 
                    (item.address.street_number ? item.address.street_number : '') + 
                    (item.address.addition ? ' ' + item.address.addition : '');
                if (fullAddress.toLowerCase().includes(term)) {
                    return true;
                }
                
                // Check Postal Code
                if (item.address.postal_code && item.address.postal_code.toLowerCase().includes(term)) {
                    return true;
                }
                
                // Check Locality/City
                if (item.address.locality && item.address.locality.toLowerCase().includes(term)) {
                    return true;
                }
                
                // Check SJHT Relation ID
                if (item.sjht_rel_id && item.sjht_rel_id.toString().toLowerCase().includes(term)) {
                    return true;
                }
                
                // Check User Name
                if (item.user && item.user.name && item.user.name.toLowerCase().includes(term)) {
                    return true;
                }
                
                // Check Status
                if (item.status) {
                    const translatedStatus = translateStatus(item.status);
                    if (translatedStatus.toLowerCase().includes(term)) {
                        return true;
                    }
                }
                
                return false;
            });
        })
        .map(item => {
            const fullAddress = item.address.route + ' ' + 
                (item.address.street_number ? item.address.street_number : '') + 
                (item.address.addition ? ' ' + item.address.addition : '');
                
            return { 
                ...item, 
                oplevernummer: 'OPL-' + item.id,
                displayAddress: fullAddress,
                displayRelationId: item.sjht_rel_id 
            };
        });
})

function onSelect(item) {
    //   if (item) {
    //     window.location = item.url
    //   }
    // Route naar het formulier komt hier
}

const toggleSearch = () => {
    open.value = !open.value;
};

const translateStatus = (status) => {
    switch (status) {
        case 'pre':
            return 'Vooroplevering';
        case 'end':
            return 'Eindoplevering';
        case 'completed':
            return 'Eindoplevering afgerond';
        case 'sent':
            return 'Entree verstuurd';
        case 'signed':
            return 'Entree ondertekend';
        default:
            return 'Onbekend';
    }
}

const statusCounts = computed(() => {
    const counts = {
        all: 0,
        vooroplevering: 0,
        eindoplevering: 0,
        entreeVerstuurd: 0,
        afgerond: 0,
        entreeOndertekend: 0,
        concept: 0
    };
    filteredByDate.value.forEach(item => {
        counts.all++;
        if (item.status === 'pre') counts.vooroplevering++;
        if (item.status === 'end') counts.eindoplevering++;
        if (item.status === 'completed') counts.afgerond++;
        if (item.status === 'sent') counts.entreeVerstuurd++;
        if (item.status === 'signed') counts.entreeOndertekend++;
        if (item.concept) counts.concept++;
    });
    return counts;
});

const statusOptions = computed(() => [
    { id: 0, name: `Alle <span class="text-black/30">(${statusCounts.value.all})` },
    { id: 1, name: `Vooroplevering <span class="text-black/30">(${statusCounts.value.vooroplevering})` },
    { id: 2, name: `Eindoplevering <span class="text-black/30">(${statusCounts.value.eindoplevering})` },
    { id: 3, name: `Eindoplevering afgerond <span class="text-black/30">(${statusCounts.value.afgerond})` },
    { id: 4, name: `Entree verstuurd <span class="text-black/30">(${statusCounts.value.entreeVerstuurd})` },
    { id: 5, name: `Entree ondertekend <span class="text-black/30">(${statusCounts.value.entreeOndertekend})` },
    { id: 6, name: `Concept <span class="text-black/30">(${statusCounts.value.concept})` }
]);

const openCreateFormModal = ref(false);
const openEntranceEmailModal = ref(false);
const selectedForm = ref(null);

const users = props.users.map(user => {
    return {
        id: user.id,
        name: user.name,
    };
});

const newForm = useForm({
    sjht_rel_id: null,
    address: null,
    date: null,
    type: 'self',
    start_status: 'pre',
    user_id: props.currentUserId
})

const entranceForm = useForm({
    email: null,
    firstname: null,
    lastname: null,
})

const statusOptionsForm = [
    { id: 'self', name: 'Zelfstandig' },
    { id: 'group', name: 'Onzelfstandig' },
];

function submitNewForm() {
    newForm.post('/formulieren', {
        preserveScroll: true,
        onSuccess: () => {
            openCreateFormModal.value = false;
        }
    });
}

function resetNewForm() {
    newForm.reset();
    newForm.errors = {};
}

function openDeleteDialog(form) {
    deleteDialog.value.open("Verwijderen", `Je staat op het punt om formulier #${form.id} te verwijderen. Weet je zeker dat je dit wilt doen?`, `/formulieren/${form.id}`);
}

function openEntranceEmailModalForm(form) {
    selectedForm.value = form;
    openEntranceEmailModal.value = true;
}

function submitEntranceEmail() {
    entranceForm.post(`/formulieren/${selectedForm.value.id}/sendEntrance`, {
        preserveScroll: true,
        onSuccess: () => {
            openEntranceEmailModal.value = false;
            selectedForm.value = null;
            entranceForm.reset();
        }
    });
}

function resetEntranceForm() {
    entranceForm.reset();
    entranceForm.errors = {};
    selectedForm.value = null;
}

// Helper function to highlight matched text (if you haven't already added this)
const highlightMatch = (text, searchTerm) => {
    if (!text || !searchTerm) return text;
    
    const regex = new RegExp(`(${searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
    return text.toString().replace(regex, '<mark class="bg-yellow-200 rounded-sm px-0.5">$1</mark>');
};
</script>

<template>

    <Head title="Formulieren" />

    <div class="flex items-center flex-col">
        <div
            class="max-w-screen-2xl w-full h-auto md:h-24 lg:h-28 px-5 py-4 relative flex items-center md:justify-center">
            <img src="/public/img/logo.svg" alt="" class="absolute left-0 h-full w-auto p-4">
            <div>
                <h1 class="ml-16 md:ml-0 text-base md:text-2xl lg:text-5xl font-bold text-white text-nowrap">Formulieren
                    overzicht</h1>
            </div>
            <div class="absolute 2xl:hidden right-0 p-3 lg:pr-8 xl:pr-16 flex flex-row items-center gap-2">
                <Button class="bg-white hover:bg-gray-100 h-9 w-9" @click="toggleSearch">
                    <font-awesome-icon :icon="['far', 'magnifying-glass']" class="text-black h-6 w-6" />
                </Button>
                <Button type="href" :href="'/formulieren/settings'" class="bg-white hover:bg-gray-100 h-9 w-9" v-if="hasPermission('house_return_forms_full')">
                    <font-awesome-icon :icon="['far', 'gear']" class="text-black h-6 w-6" />
                </Button>
                <Button @click="openCreateFormModal = true" class="bg-krz hover:bg-kr h-9 w-auto">
                    <font-awesome-icon :icon="['far', 'plus']" class="text-white h-6 w-6" />
                    <p class="hidden md:block font-semibold text-white pr-1">Formulier</p>
                </Button>
            </div>
        </div>
        <div
            class="max-w-screen-2xl w-full flex-1 px-3 md:px-6 lg:px-8 xl:px-16 flex flex-row justify-between items-center">
            <div class="flex flex-row rounded-md gap-2">
                <DateRange v-model="dateFilter" :options="dateOptions" :value-id="'id'" :value-name="'name'" />
                <div class="sm:w-44 lg:hidden">
                    <Select v-model="activeTab" :options="statusOptions" valueName="name" valueId="id"
                        placeholder="Alle" />
                </div>
                <div class="hidden lg:flex flex-row bg-gray-200 p-1 gap-1 rounded-md">
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-white': activeTab === 0 }" @click="setActiveTab(0)">
                        <p class="font-light">Alle</p>
                        <p class="text-black/30">{{ statusCounts.all }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-white': activeTab === 1 }" @click="setActiveTab(1)">
                        <p class="font-light">Vooroplevering</p>
                        <p class="text-black/30">{{ statusCounts.vooroplevering }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-white': activeTab === 2 }" @click="setActiveTab(2)">
                        <p class="font-light">Eindoplevering</p>
                        <p class="text-black/30">{{ statusCounts.eindoplevering }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-white': activeTab === 3 }" @click="setActiveTab(3)">
                        <p class="font-light">Eindoplevering afgerond</p>
                        <p class="text-black/30">{{ statusCounts.afgerond }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-white': activeTab === 4 }" @click="setActiveTab(4)">
                        <p class="font-light">Entree verstuurd</p>
                        <p class="text-black/30">{{ statusCounts.entreeVerstuurd }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-white': activeTab === 5 }" @click="setActiveTab(5)">
                        <p class="font-light">Entree ondertekend</p>
                        <p class="text-black/30">{{ statusCounts.entreeOndertekend }}</p>
                    </div>
                    <div class="flex flex-row gap-1 rounded-md px-1.5 py-0.5 duration-300 transition-all ease-in-out hover:shadow-md hover:bg-gray-100 cursor-pointer"
                        :class="{ 'bg-white': activeTab === 6 }" @click="setActiveTab(6)">
                        <p class="font-light">Concept</p>
                        <p class="text-black/30">{{ statusCounts.concept }}</p>
                    </div>
                </div>
            </div>
            <div class="hidden 2xl:flex flex-row items-center gap-2">
                <Button class="bg-white hover:bg-gray-100 h-9 w-9" @click="toggleSearch">
                    <font-awesome-icon :icon="['far', 'magnifying-glass']" class="text-black h-6 w-6" />
                </Button>
                <Button type="href" :href="'/formulieren/settings'" class="bg-white hover:bg-gray-100 h-9 w-9" v-if="hasPermission('house_return_forms_full')">
                    <font-awesome-icon :icon="['far', 'gear']" class="text-black h-6 w-6" />
                </Button>
                <Button @click="openCreateFormModal = true" class="bg-krz hover:bg-kr h-9 w-auto">
                    <font-awesome-icon :icon="['far', 'plus']" class="text-white h-6 w-6" />
                    <p class="hidden md:block font-semibold text-white pr-1">Formulier</p>
                </Button>
            </div>
        </div>
    </div>


    <div class="flex justify-center">
        <div class="max-w-screen-2xl relative w-full px-3 md:px-6 lg:px-8 xl:px-16">
            <div class="my-2 md:my-4 bg-white rounded-lg p-2 overflow-x-scroll">
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th scope="col"
                                class="hidden sm:table-cell bg-gray-50 rounded-l-lg px-2 py-3 text-left text-base/4 font-medium text-gray-600">
                                Oplevernummer</th>
                            <th scope="col"
                                class="bg-gray-50 px-2 py-3 text-left text-base/4 font-medium text-gray-600">Adres</th>
                            <th scope="col"
                                class="hidden lg:table-cell bg-gray-50 px-2 py-3 text-left text-base/4 font-medium text-gray-600">
                                Postcode</th>
                            <th scope="col"
                                class="hidden lg:table-cell bg-gray-50 px-2 py-3 text-left text-base/4 font-medium text-gray-600">
                                Woonplaats</th>
                            <th scope="col"
                                class="bg-gray-50 px-2 py-3 text-left text-base/4 font-medium text-gray-600">
                                Relatienummer</th>
                            <th scope="col"
                                class="hidden xl:table-cell bg-gray-50 px-2 py-3 text-left text-base/4 font-medium text-gray-600">
                                Opleveraar</th>
                            <th scope="col"
                                class="bg-gray-50 px-2 py-3 text-left text-base/4 font-medium text-gray-600">Status</th>
                            <th scope="col"
                                class="hidden xl:table-cell bg-gray-50 px-2 py-3 text-left text-base/4 font-medium text-gray-600">
                                Aangemaakt</th>
                            <th scope="col"
                                class="hidden lg:table-cell bg-gray-50 px-2 py-3 text-left text-base/4 font-medium text-gray-600">
                                Laatste update</th>
                            <th scope="col"
                                class="bg-gray-50 rounded-r-lg px-2 py-3 text-left text-base/4 font-medium text-gray-600 w-8">
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="item in filteredStatus" :key="item.id">
                            <td
                                class="hidden sm:table-cell px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">
                                OPL-{{ item.id }}</td>
                            <td class="px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">
                                {{ item.address.route + ' ' + item.address.street_number + (item.address.addition ? ' '
                                    + item.address.addition : '') }}
                            </td>
                            <td
                                class="hidden lg:table-cell px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">
                                {{ item.address.postal_code }}</td>
                            <td
                                class="hidden lg:table-cell px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">
                                {{ item.address.locality }}</td>
                            <td class="px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">{{
                                item.sjht_rel_id }}</td>
                            <td
                                class="hidden xl:table-cell px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">
                                {{ item.user.name }}</td>
                            <td class="px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">
                                <div class="flex flex-row items-center md:gap-2">
                                    <div :class="{
                                        'border-kr/60 border-[1px] bg-kr/10 text-kr': item.status === 'pre',
                                        'border-og/60 border-[1px] bg-og/10 text-og': item.status === 'end' || item.status === 'completed',
                                        'border-hb/60 border-[1px] bg-hb/10 text-hb': item.status === 'sent',
                                        'border-wg/60 border-[1px] bg-wg/10 text-wg': item.status === 'signed',
                                    }" class="px-2 py-2 md:py-1.5 rounded-full">
                                        <p class="hidden md:block">{{ translateStatus(item.status) }}</p>
                                    </div>
                                    <div v-if="item.concept == true"
                                        class="border-gray-400 md:border-[1px] md:bg-gray-200/10 text-gray-400 rounded-full h-8 px-2.5 gap-2 py-1.5 flex items-center justify-center">
                                        <font-awesome-icon :icon="['far', 'pen-ruler']"
                                            class="text-gray-500 h-3.5 md:h-4 w-3.5 md:w-4" />
                                        <p class="hidden lg:block">Concept</p>
                                    </div>
                                </div>
                            </td>
                            <td
                                class="hidden xl:table-cell px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">
                                {{ moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td>
                            <td
                                class="hidden lg:table-cell px-1.5 py-3 whitespace-nowrap text-base/4 font-light text-gray-700">
                                {{ moment(item.updated_at).format('DD-MM-YYYY HH:mm') }}</td>
                            <td class="py-3 whitespace-nowrap text-base/4 font-light text-gray-700 w-8">
                                <Menu as="div" class="inline-block text-left">
                                    <MenuButton
                                        class="bg-white hover:bg-gray-100 transition-all duration-500 border-[1px] h-8 w-8 rounded-md flex items-center justify-center">
                                        <font-awesome-icon :icon="['far', 'ellipsis']" class="h-5 w-5" />
                                    </MenuButton>

                                    <transition enter-active-class="transition duration-100 ease-out"
                                        enter-from-class="transform scale-95 opacity-0"
                                        enter-to-class="transform scale-100 opacity-100"
                                        leave-active-class="transition duration-75 ease-in"
                                        leave-from-class="transform scale-100 opacity-100"
                                        leave-to-class="transform scale-95 opacity-0">
                                        <div
                                            class="absolute max-w-screen-2xl w-full px-3 md:px-6 lg:px-8 xl:px-16 left-0 right-0 flex justify-end">
                                            <MenuItems
                                                class="z-30 mt-2 mr-2 w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                <div class="px-1 py-1">
                                                    <MenuItem v-slot="{ active }">
                                                    <Link :href="'/formulieren/' + item.id + '/edit'"
                                                        :class="[active ? 'bg-gray-100 border-[1px] py-[7px]' : 'py-2', 'group flex w-full items-center rounded-md px-2 text-sm',]">
                                                    <font-awesome-icon :icon="['far', 'pen-to-square']"
                                                        class="h-5 w-5 mr-2" />
                                                    Aanpassen
                                                    </Link>
                                                    </MenuItem>
                                                </div>
                                                <div class="px-1 py-1" v-if="item.start_status == 'pre'">
                                                    <MenuItem v-slot="{ active }" v-if="item.status != 'pre'">
                                                    <a :href="'/formulieren/' + item.id + '/prePdf'" target="_blank"
                                                        :class="[active ? 'bg-gray-100 border-[1px] py-[7px]' : 'py-2', 'group flex w-full items-center rounded-md px-2 text-sm',]">
                                                        <font-awesome-icon :icon="['far', 'download']"
                                                            class="h-5 w-5 mr-2" />
                                                        Vooroplevering downloaden
                                                    </a>
                                                    </MenuItem>
                                                    <MenuItem v-slot="{ active }"
                                                        v-if="item.status != 'end' && item.status != 'pre'">
                                                    <a :href="'/formulieren/' + item.id + '/endPdf'" target="_blank"
                                                        :class="[active ? 'bg-gray-100 border-[1px] py-[7px]' : 'py-2', 'group flex w-full items-center rounded-md px-2 text-sm',]">
                                                        <font-awesome-icon :icon="['far', 'download']"
                                                            class="h-5 w-5 mr-2" />
                                                        Eindoplevering downloaden
                                                    </a>
                                                    </MenuItem>
                                                    <MenuItem v-slot="{ active }"
                                                        v-if="item.status != 'end' && item.status != 'pre'">
                                                    <a :href="'/formulieren/' + item.id + '/entreePdf'" target="_blank"
                                                        :class="[active ? 'bg-gray-100 border-[1px] py-[7px]' : 'py-2', 'group flex w-full items-center rounded-md px-2 text-sm',]">
                                                        <font-awesome-icon :icon="['far', 'download']"
                                                            class="h-5 w-5 mr-2" />
                                                        Entreeformulier downloaden
                                                    </a>
                                                    </MenuItem>
                                                </div>
                                                <div class="px-1 py-1" v-if="item.status === 'completed'">
                                                    <MenuItem v-slot="{ active }">
                                                    <button @click="openEntranceEmailModalForm(item)"
                                                        :class="[active ? 'bg-gray-100 border-[1px] py-[7px]' : 'py-2', 'group flex w-full items-center rounded-md px-2 text-sm hover:cursor-pointer',]">
                                                        <font-awesome-icon :icon="['far', 'paper-plane']"
                                                            class="h-5 w-5 mr-2" />
                                                        Entree versturen
                                                    </button>
                                                    </MenuItem>
                                                </div>
                                                <div class="px-1 py-1">
                                                    <MenuItem v-slot="{ active }">
                                                    <button @click="openDeleteDialog(item)"
                                                        :class="[active ? 'bg-gray-100 border-[1px] py-[7px]' : 'py-2 ', 'group flex w-full items-center rounded-md px-2 text-sm hover:cursor-pointer',]">
                                                        <font-awesome-icon :icon="['far', 'trash']"
                                                            class="h-5 w-5 mr-2" />
                                                        Verwijderen
                                                    </button>
                                                    </MenuItem>
                                                </div>
                                            </MenuItems>
                                        </div>
                                    </transition>
                                </Menu>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <TransitionRoot :show="open" as="template" @after-leave="query = ''" appear @key.escape="open = false">
        <Dialog class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed top-0 bottom-0 left-0 right-0 bg-black/30 transition-opacity" />
            </TransitionChild>

            <div class="fixed top-0 bottom-0 left-0 right-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95">
                    <DialogPanel
                        class="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all">
                        <Combobox @update:modelValue="onSelect">
                            <div class="grid grid-cols-1">
                                <ComboboxInput
                                    class="col-start-1 row-start-1 h-12 w-full pl-11 pr-4 text-base text-gray-900 outline-none placeholder:text-gray-400 sm:text-sm"
                                    placeholder="Zoeken..." @change="query = $event.target.value" @blur="query = ''" />
                                <font-awesome-icon :icon="['far', 'magnifying-glass']"
                                    class="pointer-events-none col-start-1 row-start-1 ml-4 size-5 self-center text-gray-400"
                                    aria-hidden="true" />
                            </div>

                            <ComboboxOptions v-if="filteredItems.length > 0" static
                                class="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800">
                                <ComboboxOption v-for="item in filteredItems" :key="item.id" :value="item" as="template"
                                    v-slot="{ active }">
                                    <li :class="['cursor-default select-none px-4 py-2', active && 'bg-gray-100']">
                                        <div class="flex flex-col space-y-2">
                                            <!-- Item information -->
                                            <div class="flex flex-col">
                                                <span class="font-semibold" v-html="item.highlightedNumber || item.oplevernummer"></span>
                                                <div class="flex justify-between">
                                                    <span class="text-gray-600" v-html="item.highlightedAddress || item.displayAddress"></span>
                                                    <span class="text-gray-500" v-html="'#' + (item.highlightedRelationId || item.displayRelationId)"></span>
                                                </div>
                                            </div>
                                            
                                            <!-- Action buttons -->
                                            <div class="flex flex-wrap gap-2 pt-1">
                                                <Button type="href" :href="'/formulieren/' + item.id + '/edit'" class="border-[1px] border-gray-300 hover:bg-gray-50">
                                                    <font-awesome-icon :icon="['far', 'pen-to-square']" class="h-3 w-3 text-gray-700" />
                                                    <p class="text-gray-700">Aanpassen</p>
                                                </Button>
                                                <Button v-if="item.status != 'pre'" type="href" :href="'/formulieren/' + item.id + '/prePdf'" target="_blank" class="border-[1px] border-gray-300 hover:bg-gray-50">
                                                    <font-awesome-icon :icon="['far', 'download']" class="h-3 w-3 text-gray-700" />
                                                    <p class="text-gray-700">Vooroplevering</p>
                                                </Button>
                                                <Button v-if="item.status != 'end' && item.status != 'pre'" type="href" :href="'/formulieren/' + item.id + '/endPdf'" target="_blank" class="border-[1px] border-gray-300 hover:bg-gray-50">
                                                    <font-awesome-icon :icon="['far', 'download']" class="h-3 w-3 text-gray-700" />
                                                    <p class="text-gray-700">Eindoplevering</p>
                                                </Button>
                                                <Button v-if="item.status != 'end' && item.status != 'pre'" type="href" :href="'/formulieren/' + item.id + '/entreePdf'" target="_blank" class="border-[1px] border-gray-300 hover:bg-gray-50">
                                                    <font-awesome-icon :icon="['far', 'download']" class="h-3 w-3 text-gray-700" />
                                                    <p class="text-gray-700">Entreeoplevering</p>
                                                </Button>                                                
                                                <Button v-if="item.status === 'completed'" @click="openEntranceEmailModalForm(item)" class="border-[1px] border-gray-300 hover:bg-gray-50">
                                                    <font-awesome-icon :icon="['far', 'paper-plane']" class="h-3 w-3 text-gray-700" />
                                                    <p class="text-gray-700">Entree Versturen</p>
                                                </Button>
                                                <Button @click="openDeleteDialog(item)" class="border-[1px] border-red-300 hover:bg-red-50">
                                                    <font-awesome-icon :icon="['far', 'trash']" class="h-3 w-3 text-red-600" />
                                                    <p class="text-red-600">Verwijderen</p>
                                                </Button>
                                            </div>
                                        </div>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>

                            <p v-if="query !== '' && filteredItems.length === 0" class="p-4 text-sm text-gray-500">Er
                                zijn geen formulieren gevonden met de zoekopdracht {{ query }}.</p>
                        </Combobox>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <TransitionRoot :show="openCreateFormModal" as="template" @after-leave="resetNewForm()" appear>
        <Dialog class="relative z-10" @close="openCreateFormModal = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed top-0 left-0 right-0 bottom-0 bg-gray-500/25 transition-opacity" />
            </TransitionChild>

            <div class="fixed top-0 left-0 right-0 bottom-0 z-10 w-screen overflow-y-auto p-0 pt-16 sm:p-16 md:p-20">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95">
                    <DialogPanel
                        class="mx-auto h-full sm:h-auto max-h-full sm:max-w-xl transform rounded-t-xl sm:rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all space-y-3 flex flex-col justify-between">
                        <form @submit.prevent="submitNewForm">
                            <div class="space-y-3 h-full px-3 pt-3 border-b-[1px] mb-4 pb-4">
                                <Input v-model="newForm.sjht_rel_id" :inputId="'sjht_rel_id'" :label="'Relatienummer'"
                                    :error="newForm.errors.sjht_rel_id" class="w-full" />

                                <GoogleAddress v-model="newForm.address" :inputId="'address'" :label="'Adres'"
                                    :error="newForm.errors.address" class="w-full" />

                                <Select v-model="newForm.type" :options="statusOptionsForm" valueName="name"
                                    valueId="id" :label="'Woningtype'" :error="newForm.errors.type" class="w-full" />

                                <Select v-model="newForm.start_status" :options="startStatuses" valueName="name" v-if="hasPermission('house_return_forms_full')"
                                    valueId="id" :label="'Status'" :error="newForm.errors.start_status" class="w-full" />

                                <Select v-model="newForm.user_id" :options="users" valueName="name" valueId="id" search v-if="hasPermission('house_return_forms_full')"
                                    :label="'Opleveraar'" :error="newForm.errors.user_id" class="w-full" />

                                <DatePicker v-model="newForm.date" :inputId="'date'" :label="'Opleverdatum'"
                                    :error="newForm.errors.date" class="w-full" />
                            </div>
                            <div class="gap-1 flex w-full justify-end px-3 pb-3">
                                <Button class="bg-white hover:bg-gray-100 border-[1px] h-9 w-auto"
                                    @click="openCreateFormModal = false">
                                    <p class="font-semibold text-black">Annuleer</p>
                                </Button>
                                <Button class="bg-krz hover:bg-kr h-9 w-auto" buttonType="submit">
                                    <p class="font-semibold text-white">Formulier aanmaken</p>
                                </Button>
                            </div>
                        </form>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <TransitionRoot :show="openEntranceEmailModal" as="template" @after-leave="resetEntranceForm()" appear>
        <Dialog class="relative z-10" @close="openEntranceEmailModal = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed top-0 left-0 right-0 bottom-0 bg-gray-500/25 transition-opacity" />
            </TransitionChild>

            <div class="fixed top-0 left-0 right-0 bottom-0 z-10 w-screen overflow-y-auto p-0 pt-16 sm:p-16 md:p-20">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 scale-95"
                    enter-to="opacity-100 scale-100" leave="ease-in duration-200" leave-from="opacity-100 scale-100"
                    leave-to="opacity-0 scale-95">
                    <DialogPanel
                        class="mx-auto h-full sm:h-auto max-h-full sm:max-w-xl transform rounded-t-xl sm:rounded-xl bg-white shadow-2xl ring-1 ring-black/5 transition-all space-y-3 flex flex-col justify-between">
                        <form @submit.prevent="submitEntranceEmail">
                            <div class="space-y-3 h-full px-3 pt-3 border-b-[1px] mb-4 pb-4">
                                <h2 class="text-lg font-medium text-gray-900">Entreeformulier versturen</h2>
                                <p class="text-sm text-gray-500" v-if="selectedForm">
                                    Verstuur het entreeformulier van {{ selectedForm.address.route }} {{ selectedForm.address.street_number }}{{ selectedForm.address.addition ? ' ' + selectedForm.address.addition : '' }}
                                </p>
                                
                                <Input v-model="entranceForm.firstname" :inputId="'firstname'" :label="'Voornaam'"
                                    :error="entranceForm.errors.firstname" class="w-full" />

                                <Input v-model="entranceForm.lastname" :inputId="'lastname'" :label="'Achternaam'"
                                    :error="entranceForm.errors.lastname" class="w-full" />

                                <Input v-model="entranceForm.email" :inputId="'email'" :label="'E-mailadres'"
                                    :error="entranceForm.errors.email" class="w-full" type="email" />
                            </div>
                            <div class="gap-1 flex w-full justify-end px-3 pb-3">
                                <Button class="bg-white hover:bg-gray-100 border-[1px] h-9 w-auto"
                                    @click="openEntranceEmailModal = false">
                                    <p class="font-semibold text-black">Annuleer</p>
                                </Button>
                                <Button class="bg-krz hover:bg-kr h-9 w-auto" buttonType="submit">
                                    <p class="font-semibold text-white">Versturen</p>
                                </Button>
                            </div>
                        </form>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>

    <DeleteDialog ref="deleteDialog" />
</template>
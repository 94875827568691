<script setup>
import { Head } from '@inertiajs/vue3';
import { ref } from 'vue';
import Button from '@/Components/Button.vue';

// Sample dashboard data
const recentForms = [
    { id: 1, address: 'Hoofdstraat 15, Enschede', date: '12-05-2023', status: 'Voltooid' },
    { id: 2, address: 'Zuiderlaan 42, Hengelo', date: '14-05-2023', status: 'Concept' },
    { id: 3, address: 'Bornsestraat 134, Enschede', date: '21-05-2023', status: 'Voltooid' },
];

const stats = [
    { title: 'Voltooide formulieren', value: 12, icon: 'check-circle', color: 'bg-ag' },
    { title: 'Open concepten', value: 3, icon: 'clock', color: 'bg-amber-500' },
    { title: 'Inschrijvingen deze maand', value: 18, icon: 'users', color: 'bg-blue-500' }
];

const userNavigation = [
    { name: 'Mijn profiel', href: '#' },
    { name: 'Instellingen', href: '#' },
    { name: 'Uitloggen', href: '#' }
];

const sidebarOpen = ref(false);

// Add sidebar collapsed state
const sidebarCollapsed = ref(false);
// Add text visibility state (with delay)
const textVisible = ref(true);

// Toggle sidebar collapsed state
const toggleSidebar = () => {
    // Different behavior based on whether we're collapsing or expanding
    if (!sidebarCollapsed.value) {
        // COLLAPSING: First hide text, then shrink sidebar
        textVisible.value = false;
        
        // Wait for text to fade out before collapsing sidebar width
        setTimeout(() => {
            sidebarCollapsed.value = true;
        }, 200); // Matches the fade-out duration
    } else {
        // EXPANDING: First expand sidebar, then show text
        sidebarCollapsed.value = false;
        
        // Wait for sidebar to expand before showing text
        setTimeout(() => {
            textVisible.value = true;
        }, 300);
    }
};
</script>

<template>
    <Head title="Dashboard" />

    <div class="flex h-screen bg-hbz">
        <!-- Sidebar (always visible on desktop) -->
        <aside :class="[sidebarCollapsed ? 'md:w-16' : 'md:w-64', 'hidden md:flex md:flex-col md:fixed md:inset-y-0 border-r border-gray-200 transition-all duration-300']">
            <div class="flex-1 flex flex-col min-h-0">
                <div class="flex items-center h-16 px-4 border-b border-gray-200">
                    <img src="/public/img/logo.svg" alt="SJHT Logo" :class="[sidebarCollapsed ? 'mx-auto' : '', 'h-8 w-auto']">
                </div>
                <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                    <nav class="mt-5 flex-1 space-y-2">
                        <a href="#" class="group flex items-center px-3 py-2 sm:text-lg font-medium rounded-md text-white hover:bg-hbz/80" :class="sidebarCollapsed ? 'justify-center' : ''">
                            <font-awesome-icon :icon="['far', 'grid-horizontal']" class="text-white h-6 w-6" :class="sidebarCollapsed ? '' : 'mr-2'" />
                            <transition name="fade">
                                <span v-if="!sidebarCollapsed && textVisible" class="whitespace-nowrap">Dashboard</span>
                            </transition>
                        </a>
                        
                        <a href="/formulieren" class="group flex items-center px-3 py-2 sm:text-lg font-medium rounded-md text-gray-300 hover:bg-hbz/80 hover:text-white" :class="sidebarCollapsed ? 'justify-center' : ''">
                            <font-awesome-icon :icon="['far', 'paper-plane']" class="text-gray-300 group-hover:text-white h-6 w-6" :class="sidebarCollapsed ? '' : 'mr-2'" />
                            <transition name="fade">
                                <span v-if="!sidebarCollapsed && textVisible" class="whitespace-nowrap">Formulieren</span>
                            </transition>
                        </a>
                        
                        <a href="#" class="group flex items-center px-3 py-2 sm:text-lg font-medium rounded-md text-gray-300 hover:bg-hbz/80 hover:text-white" :class="sidebarCollapsed ? 'justify-center' : ''">
                            <font-awesome-icon :icon="['far', 'user']" class="text-gray-300 group-hover:text-white h-6 w-6" :class="sidebarCollapsed ? '' : 'mr-2'" />
                            <transition name="fade">
                                <span v-if="!sidebarCollapsed && textVisible" class="whitespace-nowrap">Gebruikers</span>
                            </transition>
                        </a>
                        
                        <a href="#" class="group flex items-center px-3 py-2 sm:text-lg font-medium rounded-md text-gray-300 hover:bg-hbz/80 hover:text-white" :class="sidebarCollapsed ? 'justify-center' : ''">
                            <font-awesome-icon :icon="['far', 'cog']" class="text-gray-300 group-hover:text-white h-6 w-6" :class="sidebarCollapsed ? '' : 'mr-2'" />
                            <transition name="fade">
                                <span v-if="!sidebarCollapsed && textVisible" class="whitespace-nowrap">Instellingen</span>
                            </transition>
                        </a>
                    </nav>
                </div>
                <div class="flex-shrink-0 flex flex-col">
                    <button 
                        @click="toggleSidebar" 
                        class="mb-2 p-1 rounded-md text-gray-300 hover:bg-hbz/80 hover:text-white flex items-center"
                        :class="sidebarCollapsed ? 'justify-center' : 'justify-end'"
                    >
                        <font-awesome-icon 
                            :icon="['far', sidebarCollapsed ? 'chevron-right' : 'chevron-left']" 
                            class="h-5 w-5" 
                        />
                        <font-awesome-icon 
                            v-if="!sidebarCollapsed"
                            :icon="['far', sidebarCollapsed ? 'chevron-right' : 'chevron-left']" 
                            class="h-5 w-5 -ml-1" 
                        />
                    </button>
                    <div :class="[sidebarCollapsed ? 'justify-center' : '', 'flex items-center']" class="border-t border-gray-200 p-4">
                        <div class="flex-shrink-0">
                            <div class="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center text-gray-500">
                                <font-awesome-icon :icon="['far', 'user']" class="h-4 w-4" />
                            </div>
                        </div>
                        <transition name="fade">
                            <div v-if="!sidebarCollapsed && textVisible" class="ml-3">
                                <p class="text-sm font-medium text-white">Admin Gebruiker</p>
                                <button class="text-xs font-medium text-gray-300 hover:text-white">Uitloggen</button>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </aside>

        <!-- Mobile hamburger menu -->
        <div class="md:hidden">
            <button @click="sidebarOpen = !sidebarOpen" class="fixed z-20 top-4 left-4 bg-hbz p-2 rounded-md text-white">
                <font-awesome-icon :icon="['far', 'bars']" class="h-6 w-6" />
            </button>
            
            <!-- Mobile sidebar -->
            <div v-if="sidebarOpen" class="fixed inset-0 z-10 flex">
                <div class="fixed inset-0 bg-black/50" @click="sidebarOpen = false"></div>
                <div class="relative flex-1 flex flex-col max-w-xs w-full bg-hbz">
                    <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                        <div class="flex-shrink-0 flex items-center px-4">
                            <img src="/public/img/logo.svg" alt="SJHT Logo" class="h-8 w-auto">
                        </div>
                        <nav class="mt-5 px-2 space-y-1">
                            <a href="#" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md text-white hover:bg-hbz/80">
                                <font-awesome-icon :icon="['far', 'tachometer-alt']" class="text-white h-5 w-5 mr-2" />
                                <span>Dashboard</span>
                            </a>
                            
                            <a href="/formulieren" class="group flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-300 hover:bg-hbz/80 hover:text-white">
                                <font-awesome-icon :icon="['far', 'paper-plane']" class="text-gray-300 group-hover:text-white h-5 w-5 mr-2" />
                                <span>Formulieren</span>
                            </a>
                            
                            <!-- Other navigation items... -->
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- Main content -->
        <div :class="[sidebarCollapsed ? 'md:pl-16' : 'md:pl-64', 'flex flex-col flex-1 transition-all duration-300']">
            <!-- Top header -->
            <header class="bg-hbz h-16 border-b border-gray-200 z-10">
                <div class="px-4 h-full flex items-center justify-between">
                    <h1 class="text-xl md:text-2xl font-bold text-white">Dashboard</h1>
                    <div class="flex items-center">
                        <span class="text-sm text-white mr-2">Gebruiker</span>
                        <div class="h-8 w-8 rounded-full bg-white flex items-center justify-center text-hb">
                            <font-awesome-icon :icon="['far', 'user']" class="h-4 w-4" />
                        </div>
                    </div>
                </div>
            </header>

            <!-- Dashboard content -->
            <main class="flex-1 overflow-y-auto bg-hbz p-4">
                <div>
                    <!-- Stats section -->
                    <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3 mb-4">
                        <div v-for="(stat, index) in stats" :key="index" class="bg-white overflow-hidden shadow rounded-lg">
                            <div class="flex items-center p-2">
                                <div :class="[stat.color, 'flex-shrink-0 rounded-md p-3']">
                                    <font-awesome-icon :icon="['far', stat.icon]" class="h-6 w-6 text-white" />
                                </div>
                                <div class="ml-2 flex-1">
                                    <dl>
                                        <dt class="text-sm font-medium text-gray-500 truncate">{{ stat.title }}</dt>
                                        <dd class="text-lg font-semibold text-gray-900">{{ stat.value }}</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Recent forms section -->
                    <div class="bg-white shadow rounded-lg overflow-clip mb-4">
                        <div class="p-2 sm:p-4 flex justify-between items-center">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">Recente formulieren</h3>
                            <Button type="href" href="/formulieren" class="bg-krz hover:bg-kr px-3 py-1 rounded-md">
                                <span class="text-white text-sm">Alle formulieren</span>
                            </Button>
                        </div>
                        <div class="border-t border-gray-200">
                            <div class="overflow-x-auto">
                                <table class="min-w-full divide-y divide-gray-200">
                                    <thead class="bg-gray-50">
                                        <tr>
                                            <th scope="col" class="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nr.</th>
                                            <th scope="col" class="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Adres</th>
                                            <th scope="col" class="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Datum</th>
                                            <th scope="col" class="p-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                        <tr v-for="form in recentForms" :key="form.id" class="hover:bg-gray-50">
                                            <td class="p-3 whitespace-nowrap text-sm font-medium text-gray-900">OPL-{{ form.id }}</td>
                                            <td class="p-3 whitespace-nowrap text-sm text-gray-500">{{ form.address }}</td>
                                            <td class="p-3 whitespace-nowrap text-sm text-gray-500">{{ form.date }}</td>
                                            <td class="p-3 whitespace-nowrap">
                                                <span :class="[
                                                    form.status === 'Voltooid' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800',
                                                    'px-2 inline-flex text-xs leading-5 font-semibold rounded-full'
                                                ]">
                                                    {{ form.status }}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <!-- Quick actions section -->
                    <div class="bg-white shadow rounded-lg">
                        <div class="p-2 sm:p-4">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">Snelle acties</h3>
                        </div>
                        <div class="border-t border-gray-200 p-2">
                            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                <Button type="href" href="/formulieren/create" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-kr hover:bg-kr/80">
                                    <font-awesome-icon :icon="['far', 'file-plus']" class="mr-2 h-5 w-5" />
                                    Nieuw formulier aanmaken
                                </Button>
                                <Button type="button" class="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700">
                                    <font-awesome-icon :icon="['far', 'search']" class="mr-2 h-5 w-5" />
                                    Zoek formulieren
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s ease;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>
<script setup>
import { ref, watch, onMounted, onUnmounted } from 'vue';

const showPopup = ref(false);

const props = defineProps({
    src: String,
    class: String,
})

defineExpose({ showPopup });

// Watch for changes in showPopup to add/remove the no-scroll class
watch(showPopup, (newValue) => {
    if (newValue) {
        document.body.classList.add('no-scroll');
    } else {
        document.body.classList.remove('no-scroll');
    }
});

// Function to handle escape key press
const handleEscape = (event) => {
    if (showPopup.value) {
        if (event.key === 'Escape') {
            showPopup.value = false;
        }
    }
};

// Add event listener for escape key when component is mounted
onMounted(() => {
    window.addEventListener('keydown', handleEscape);
});

// Remove event listener when component is unmounted
onUnmounted(() => {
    window.removeEventListener('keydown', handleEscape);
});

</script>

<template>
    <div :class="class" class="relative group rounded-lg overflow-hidden bg-white pb-12 pt-5 shadow sm:pt-6 border-[1px] hover:shadow-lg duration-150">
        <button @click="showPopup = true" class="absolute top-0 left-0 right-0 bottom-0 bg-black/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10 h-full w-full flex items-center justify-center">
            <font-awesome-icon :icon="['far', 'eye']" class="text-white h-8 w-8" />
        </button>
        <img :src="src" class="absolute top-0 left-0 right-0 bottom-0 object-cover h-full w-full" />
    </div>

    <div v-if="showPopup" @click="showPopup = false" class="fixed top-0 left-0 right-0 bottom-0 bg-black/80 z-50 flex items-center justify-center w-full h-full">
        <div class="relative flex items-center justify-center w-full h-full">
            <img @click.stop :src="src" class="rounded-2xl w-auto max-w-[100%] h-auto max-h-[100%] object-contain" />
        </div>
        <button @click="showPopup = false" class="absolute top-4 right-4">
            <font-awesome-icon :icon="['far', 'xmark']" class="h-8 w-8 text-white hover:text-gray-300" />
        </button>
    </div>
</template>

<style>
/* Add a class to disable scrolling */
.no-scroll {
    overflow: hidden;
}
</style>
<script setup>
/*
* This is a simple textarea component that can be used in forms.
* It accepts a label, error message, input ID, input type, and whether the input is required.
* The input is styled with Tailwind CSS.
* When an error is present, a red border is displayed around the input and a red exclamation triangle is displayed to the right of the input.
*
* @param {String} label - The label for the input.
* @param {String} error - The error message to display.
* @param {String} inputId - The ID for the input.
* @param {String} type - The type of input.
* @param {Boolean} required - Whether the input is required.
*/


import { readonly, ref, watch } from 'vue';

const model = defineModel();

const props = defineProps({
    label: String,
    error: String,
    inputId: String,
    type: {
        type: String,
        default: 'text',
    },
    required: {
        type: Boolean,
        default: false,
    },
    maxLength: {
        type: Number,
        default: 255,
    },
    readOnly: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const input = ref(null);

const error = ref(props.error);

//if error is present, focus on the input
watch(() => props.error, (value) => {
    error.value = value;
}, {deep:true});
</script>

<template>
    <div class="flex flex-row justify-between" v-if="label">
        <label :for="inputId" class="block text-sm font-semibold text-gray-900">{{ label }}</label>
        <div class="text-gray-500 text-xs pt-1">(nog {{ maxLength -  (model != null ? model.length : 0)  }} karakters)</div>
    </div>

    <div :class="[label ? 'mt-2 flex' : 'flex']">
        <textarea v-model="model" ref="input" :id="inputId" :type="type" :autocomplete="inputId" :required="required" :name="inputId"
            :class="error ? 'ring-red-500!' : ''" :maxlength="maxLength" :disabled="disabled" :readonly="readOnly"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-xs ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-krz! sm:text-sm sm:leading-6 duration-200  h-16" />
        <div v-if="error" class="absolute pointer-events-none mt-1.5 right-10">
            <font-awesome-icon :icon="['far', 'triangle-exclamation']" class="h-5 w-5 text-red-500 shrink-0"
                aria-hidden="true" />
        </div>
    </div>

    <p class="mt-2 text-sm text-red-600" v-if="error">
        {{ error }}
    </p>
</template>

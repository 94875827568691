<script setup>
/**
 * This is a date picker component that can be used in forms.
 * It accepts a label, error message, input ID and placeholder.
 * The input is styled with Tailwind CSS.
 * When an error is present, a red border is displayed around the input and a red exclamation triangle is displayed to the right of the input.
 * 
 * @param {String} label - The label for the input.
 * @param {String} error - The error message to display.
 * @param {String} inputId - The ID for the input.
 * @param {String} placeholder - The placeholder for the input.
 * @param {Boolean} required - Whether the input is required.
 */

import { computed, ref, watch, onMounted } from 'vue';
import moment from 'moment/moment';
import 'moment/dist/locale/nl';

const props = defineProps({
    label: String,
    error: String,
    inputId: String,
    placeholder: {
        type: String,
        default: '',
    },
    required: {
        type: Boolean,
        default: false,
    }
})

const model = defineModel();
const input = ref(null);

const datePickerOpen = ref(false);
const datePickerView = ref(null);

function openDatePicker() {
    datePickerOpen.value = !datePickerOpen.value;
    view.value = 'day';

    currentMonth.value = model.value ? moment(model.value, 'DD-MM-YYYY') : moment();
}

const currentMonth = ref(moment());

function prevMonth() {
    if (view.value === 'month') {
        currentMonth.value = moment(currentMonth.value).subtract(1, 'year');
    }
    if (view.value === 'day') {
        currentMonth.value = moment(currentMonth.value).subtract(1, 'month');
    }
    if (view.value === 'year') {
        currentMonth.value = moment(currentMonth.value).subtract(10, 'year');
    }
}

function nextMonth() {
    if (view.value === 'month') {
        currentMonth.value = moment(currentMonth.value).add(1, 'year');
    }
    if (view.value === 'day') {
        currentMonth.value = moment(currentMonth.value).add(1, 'month');
    }
    if (view.value === 'year') {
        currentMonth.value = moment(currentMonth.value).add(10, 'year');
    }
}

const daysToDisplay = computed(() => {
    const firstDay = moment(currentMonth.value).startOf('month').startOf('isoWeek').day(1);
    const lastDay = moment(currentMonth.value).endOf('month').endOf('isoWeek').day(0);



    const days = [];
    let day = firstDay.clone();

    while (day.isBefore(lastDay, 'day') || day.isSame(lastDay, 'day')) {
        if (day.isoWeekday() === 1) {
            days.push(day.isoWeek());
        }
        days.push(day.clone());
        day.add(1, 'day');
    }

    return days;
})

const selectedDate = ref(model.value ? moment(model.value) : null);

function select(day) {
    selectedDate.value = day.clone();
    model.value = day.clone().format('DD-MM-YYYY');
    datePickerOpen.value = false;
}

const view = ref('day');

function changeView(newView) {
    view.value = newView;
}

function setMonth(month) {
    currentMonth.value = moment(currentMonth.value).month(month);
    view.value = 'day';
}

function setYear(year) {
    currentMonth.value = moment(currentMonth.value).year(year);
    view.value = 'month';
}

function manualChange(event) {

    //if the input is empty or invalid, set the model to null\


    if (!event.target.value || !moment(event.target.value, 'DD-MM-YYYY').isValid()) {
        model.value = null;
        selectedDate.value = null;
        return;
    }

    model.value = event.target.value;

    selectedDate.value = moment(event.target.value, 'DD-MM-YYYY');
    currentMonth.value = moment(event.target.value, 'DD-MM-YYYY');
}

//if error is present, focus on the input
watch(() => props.error, (value) => {
    error.value = value;
}, { deep: true });

const error = ref(props.error);

const style = computed(() => {
    if (!datePickerOpen.value) return;
    if (!datePickerView.value || !input.value) return;

    // Calculate the height of the datePickerView and determine if it should be above or below the input
    const inputRect = input.value.getBoundingClientRect();
    const datePickerViewRect = datePickerView.value.getBoundingClientRect();
    const spaceBelow = window.innerHeight - inputRect.bottom;
    const spaceAbove = inputRect.top;

    if (spaceBelow < datePickerViewRect.height && spaceAbove > datePickerViewRect.height) {
        return {
            bottom: '100%',
            top: 'auto',
        };
    } else {
        return {
            top: '100%',
            bottom: 'auto',
        };
    }
});

onMounted(() => {
    document.addEventListener('mousedown', (event) => {
        if (!datePickerView.value) return;

        if (datePickerOpen.value && !datePickerView.value.contains(event.target) && !document.getElementById(props.inputId).contains(event.target)) {
            datePickerOpen.value = false;
        }
    });
})
</script>

<template>
    <div v-auto-animate>
        <label v-if="label" :for="inputId" class="block text-sm font-semibold text-gray-900">{{ label }}</label>

        <div :class="[label ? 'mt-2 relative' : 'relative']">

            <font-awesome-icon :icon="['far', 'calendar']" class="absolute left-3 top-1/2 transform -translate-y-1/2" />


            <input v-model="model" @click="openDatePicker" ref="input" :id="inputId" :autocomplete="inputId"
                :name="inputId" :class="error ? 'ring-red-500! pr-9' : ''" :placeholder="placeholder"
                :required="required" @change="manualChange"
                class="pl-9 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-xs ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-krz! sm:text-sm sm:leading-6 duration-200" />

            <font-awesome-icon v-if="error" :icon="['far', 'triangle-exclamation']"
                class="absolute pointer-events-none right-3 top-1/2 transform -translate-y-1/2 text-red-500"
                aria-hidden="true" />

            <div v-auto-animate="{ duration: 150 }">
                <div ref="datePickerView" v-if="datePickerOpen" :style="style"
                    class="absolute mt-1 rounded-md bg-white shadow-lg z-10 border w-72 p-5">
                    <div class="flex items-center">
                        <button type="button" @click="prevMonth"
                            class="-my-1.5 flex flex-none items-center justify-center border-2 rounded-md hover:bg-gray-200 duration-200 p-1.5 text-gray-400 hover:text-gray-700">
                            <span class="sr-only">Vorige maand</span>
                            <font-awesome-icon :icon="['far', 'chevron-left']" class="h-3 w-3" />
                        </button>
                        <button v-if="view == 'day'" type="button" @click="changeView('month')"
                            class="flex p-1 -m-1 mx-auto text-sm font-semibold text-gray-900 hover:bg-gray-100 rounded-lg px-3 duration-200">
                            <h2>{{ currentMonth.format('MMMM YYYY') }}</h2>
                        </button>
                        <button v-if="view == 'month'" type="button" @click="changeView('year')"
                            class="flex p-1 -m-1 mx-auto text-sm font-semibold text-gray-900 hover:bg-gray-100 rounded-lg px-3 duration-200">
                            <h2>{{ currentMonth.format('YYYY') }}</h2>
                        </button>
                        <button v-if="view == 'year'" type="button" @click="changeView('year')"
                            class="flex p-1 -m-1 mx-auto text-sm font-semibold text-gray-900 cursor-default">
                            <h2>{{ currentMonth.format('YYYY') }} - {{ moment(currentMonth).add(9,
                                'year').format('YYYY') }}</h2>
                        </button>
                        <button type="button" @click="nextMonth"
                            class="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center border-2 rounded-md hover:bg-gray-200 justify-center p-1.5 duration-200 text-gray-400 hover:text-gray-700">
                            <span class="sr-only">Volgende maand</span>
                            <font-awesome-icon :icon="['far', 'chevron-right']" class="h-3 w-3" />
                        </button>
                    </div>
                    <div v-if="view == 'day'">
                        <div class="mt-5 grid grid-cols-8 text-center text-xs/6 text-gray-700 font-bold">
                            <div class="font-bold text-krz">Wk</div>
                            <div>Ma</div>
                            <div>Di</div>
                            <div>Wo</div>
                            <div>Do</div>
                            <div>Vr</div>
                            <div>Za</div>
                            <div>Zo</div>
                        </div>
                        <div id="days-container" class="mt-2 grid grid-cols-8 text-sm">
                            <div class="py-2" v-for="day in daysToDisplay" v-auto-animate>
                                <div v-if="typeof day === 'number'"
                                    class="font-bold text-xs h-full flex justify-center items-center text-krz bg-krz/10 rounded-md">
                                    {{ day }}</div>
                                <button v-else type="button" @click="select(day)" :class="[
                                    'mx-auto flex size-8 items-center justify-center rounded-md',
                                    selectedDate && selectedDate.isSame(day, 'day') ? 'text-white' : '',
                                    selectedDate && selectedDate.isSame(day, 'day') ? 'bg-krz hover:text-gray-900 shadow-lg' : '',
                                    moment().isSame(day, 'day') ? 'text-krz' : '',
                                    day.isSame(currentMonth, 'month') ? 'text-gray-900 hover:bg-gray-200 duration-200' : '',
                                    !day.isSame(currentMonth, 'month') ? 'text-gray-400' : '',
                                    !day.isSame(currentMonth, 'month') ? 'hover:bg-gray-200 bg-gr' : '',
                                    selectedDate && selectedDate.isSame(day, 'day') ? 'font-semibold' : '',
                                    selectedDate && selectedDate.isSame(day, 'day') ? 'hover:bg-gray-200 shadow-lg' : '',
                                ]">
                                    <time datetime="2021-12-27">{{ day.format('DD') }}</time>
                                </button>
                            </div>
                        </div>
                        <div class="flex justify-end mt-3">
                            <button type="button" @click="select(moment())"
                                class="text-gray-500 hover:text-gray-800 hover:bg-gray-200 px-3 py-1 rounded-lg duration-200 text-sm">
                                Vandaag
                            </button>
                        </div>
                    </div>

                    <div v-if="view == 'month'">
                        <div class="grid grid-cols-3 gap-2">
                            <div v-for="month in moment.months()" :key="month" class="py-2">
                                <button type="button" @click="setMonth(moment().month(month).month())"
                                    class="flex size-8 items-center justify-center rounded-lg w-full text-gray-900 hover:bg-gray-200 duration-200">
                                    {{ month }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="view == 'year'">
                        <div class="grid grid-cols-3 gap-4">
                            <div v-for="(year, index) in 10" :key="year" class="py-2">
                                <button type="button" @click="setYear(currentMonth.year() + index)"
                                    class="mx-auto flex size-8 items-center justify-center rounded-lg w-full text-gray-900 hover:bg-gray-200 duration-200">
                                    {{ currentMonth.year() + index }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <p v-if="error" class="mt-2 text-sm text-red-600">{{ error }}</p>
    </div>
</template>
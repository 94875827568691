<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    class: String,
    data_intercom_target: String,
    type: {
        type: String,
        default: 'default',
    },
    buttonType: {
        type: String,
        default: 'button',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    href: String,
})

const emit = defineEmits(['click'])

</script>

<template>

    <button @click="emit('click')" v-if="type === 'link'" :class="class" :type="buttonType" :disabled="disabled"
        :data-intercom-target="data_intercom_target" class="text-krz hover:text-kr duration-200">
        <slot></slot>
    </button>

    <Link :class="class" :data-intercom-target="data_intercom_target" :href="href" v-else-if="type === 'href'"
        class="flex gap-1 items-center justify-center rounded-md transition-all hover:shadow-lg cursor-pointer p-1.5 text-sm font-semibold text-white shadow-sm duration-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        <slot></slot>
    </Link>

    <button @click="emit('click')" v-else :class="class" :data-intercom-target="data_intercom_target" :type="buttonType" :disabled="disabled"
        class="flex gap-1 items-center justify-center rounded-md transition-all hover:shadow-lg cursor-pointer p-1.5 text-sm font-semibold text-white shadow-sm duration-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
        <slot></slot>
    </button>
</template>